/**
 * Add KPIs/Metrics modal
 * Created On: 26-08-2024
 * Modified On: 27-12-2024
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react/Modal';
import MotifButton, { MotifIconButton } from '@ey-xd/motif-react/Button';
import MotifFormField from '@ey-xd/motif-react/FormField';
import MotifSelect, { MotifOption } from '@ey-xd/motif-react/Select';
import MotifAccordion, { MotifAccordionTrigger, MotifAccordionContent } from "@ey-xd/motif-react/Accordion";
import MotifTable from '@ey-xd/motif-react/Table';
import MotifToast from '@ey-xd/motif-react/Toast';
import MotifTooltip from '@ey-xd/motif-react/Tooltip';
import { MotifInput, MotifErrorMessage, MotifLabel } from '@ey-xd/motif-react';

//  Icons imports
import iconAddWhite from '../../../../assets/images/add-circle-white.svg'
import iconTrash from '../../../../assets/images/trash.svg';
import infoCircle from '../../../../assets/images/info-circle.svg';
import iconNoRelationship from '../../../../assets/images/no-relation.svg';
import iconError from '../../../../assets/images/error.svg';
import iconWhiteCheckCircle from "../../../../assets/images/white-check-circle.svg";
import iconSuccess from "../../../../assets/images/check-circle.svg";
import iconArrowBack from '../../../../assets/images/arrow_back.svg';
import iconAddKPI from "../../../../assets/images/iconAddKPI.svg";
import iconDiscardKPI from "../../../../assets/images/iconDiscardKPI.svg";
import iconAddKPIBlue from "../../../../assets/images/iconAddKPIBlue.svg";

//  Internal imports
import './AdditionalContext.scss';
import TableService from '../../../../services/tableService';
import DeleteAdditionalContext from '../deleteAdditionalContext/DeleteAdditionalContext';
import CommonService from '../../../../services/commonService';

const AdditionalContext = ({
    openModal,
    setOpenModal,
    closeModal,
    selectedTable
}) => {

    //  Constant Error messages
    const infoMessageRangeDefaultGeneric = 'Periods selected for context should match periods for row items.';
    const infoMessageRangeDefaultComposition = 'Periods selected should match periods for row items.';
    const infoCharLimitGeneric = 'The information entered above will impact the response.';
    const infoCharLimitReached = 'Maximum limit reached - 50 Characters';
    const errorMessageDuplicateRange = 'Range already exists. Add a different range';

    const { user, activeProject } = useSelector(state => state.user);
    const projectId = activeProject?.project_UUID;
    const vCurrentUserEmail = user?.username;

    const tableService = new TableService();
    const commonService = new CommonService();
    let additionalContextList = [];

    const [errorMessageCharLimit, setErrorMessageCharLimit] = useState(infoCharLimitGeneric);
    const [selectedTableRow, setSelectedTableRow] = useState("");
    const [disableConfirmButton, setDisableConfirmButton] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [additionalContextRowHeaderList, setAdditionalContextRowHeaderList] = useState([]);
    const [additionalContexttableList, setAdditionalContexttableList] = useState([]);
    const [showEditToast, setShowEditToast] = useState(false);
    const [showClose, setShowClose] = useState(true);
    const [showDACModal, setShowDACModal] = useState(false);
    const [showDACToast, setShowDACToast] = useState(false);
    const [selectedKpiId, setSelectedKpiId] = useState(null);
    const [natureOfInputRefs, setNatureOfInputRefs] = useState([]);
    const [addConViewResponseData, setAddConViewResponseData] = useState([]);
    const [kpiList, setKpiList] = useState([]);
    const [errors, setErrors] = useState({});
    const [selectedRangeErrors, setSelectedRangeErrors] = useState({});
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
    const [selectedDropdown, setSelectedDropdown] = useState('');
    const isInValidDescription = Object.values(errors).some(error => error);
    const isInValidSelectedRange = Object.values(selectedRangeErrors).some(error => error);

    /**
     * Hook to trigger set add button enable dor disabled
     */
    useEffect(() => {
        validateFields(kpiList);
        //  eslint-disable-next-line
    }, [kpiList]);

    /**
     * Hook to get the additional context data
     */
    useEffect(() => {
        if (selectedTable) {
            getAdditionalContext();
        }
        // eslint-disable-next-line
    }, [selectedTable]);

    /**
     * api call to get nature of input references
     */
    const getReferences = async (vExcludeComposition) => {
        const response = await commonService.getReferences();
        if (response) {
            const vNatureInputRefs = response.filter(element => (element.GroupKey === "Nature - KPI") && !(vExcludeComposition && element.ReferenceKey === 'Composition'));
            setNatureOfInputRefs(vNatureInputRefs);
        }
    };

    /**
     * Hook to populate nature kpi type references
     */
    useEffect(() => {
        getReferences(false);
        // eslint-disable-next-line
    }, []);

    /**
      * Function to open a Delete KPIs/Metrics modal.
      */
    const handleOpenModal = (kpiId) => {
        setSelectedKpiId(kpiId)
        setShowDACModal(true);
    };

    /**
     * Function to handle add button
     */
    const handleAddKPI = () => {
        setKpiList([
            {
                description: '',
                range: ''
            },
            ...kpiList
        ]);
        setErrors({});
        setSelectedRangeErrors({});
        setIsAddButtonDisabled(true);
    };

    /**
     * Function when changing input description field
     * @param {*} index 
     * @param {*} data 
     */
    const onInputDescChange = (index, data) => {
        const vValid = /^[a-zA-Z0-9 %]*$/.test(data);
        const newErrors = { ...errors };
        if ((data === '') || (data.trim() === '')) {
            newErrors[index] = 'Please enter a valid alphanumeric description with a maximum of 50 characters.';
        } else if (!vValid) {
            newErrors[index] = 'Special characters not allowed';
        } else {
            delete newErrors[index];
        }
        setErrors(newErrors);

        if (data.length <= 50) {
            const newKpiList = [...kpiList];
            newKpiList[index].description = data;
            setKpiList(newKpiList);
        } else if (data.length > 50) {
            setErrorMessageCharLimit(infoCharLimitReached);
        } else {
            setErrorMessageCharLimit(infoCharLimitGeneric);
        }
    };

    /**
     * Function to set add button enabled or disabled
     * @param {*} vkpiList 
     */
    const validateFields = (vkpiList) => {
        const allFieldsFilled = vkpiList.every(kpi => {
            return (
                (
                    (selectedDropdown === 'Composition') ||
                    (
                        (selectedDropdown !== 'Composition') && kpi.description
                    )
                ) &&
                kpi.range
            )
        });
        setIsAddButtonDisabled(!allFieldsFilled || isInValidDescription || isInValidSelectedRange);
    };

    /**
     * Funciton to get selected range from excel
     * @param {*} index 
     */
    const getSelectedRange = (index) => {
        if (window.Excel) {
            window.Excel.run(async (context) => {
                const selectedRange = context.workbook.getSelectedRange();
                selectedRange.load(["values", "address", "worksheet/name"]);
                await context.sync();

                const values = selectedRange.values;
                const sheetName = selectedRange.worksheet.name;
                const selectedAddress = selectedRange?.address;
                const vSelectedRangeError = validateSelectedRange(values, sheetName, selectedAddress, index);
                const vSelectedRangeErrors = { ...selectedRangeErrors };
                if (vSelectedRangeError) {
                    vSelectedRangeErrors[index] = vSelectedRangeError;
                } else {
                    delete vSelectedRangeErrors[index];
                }
                setSelectedRangeErrors(vSelectedRangeErrors);

                if (selectedRange?.address && (selectedRange.address.indexOf(':') !== -1)) {
                    const newKpiList = [...kpiList];
                    newKpiList[index].range = selectedRange.address;
                    setKpiList(newKpiList);
                }

                const address = selectedRange.address
                    .replace(selectedRange.worksheet.name, "")
                    .replace(/[&#,+()$~%.'"*?!<>{}]/g, "");

                let vSelectedValues;
                if (values && (values.length > 0)) {
                    vSelectedValues = values.reduce((prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])), []);
                }

                const workbook = context.workbook;
                workbook.load("name");
                await context.sync();
                const workbookName = workbook.name;

                const result = {
                    additional_context_data: vSelectedValues,
                    additional_context_metadata: {
                        filename: workbookName,
                        sheetName: sheetName,
                        uploadRange: address,
                    }
                };
                if (result) {
                    const newKpiList = [...kpiList];
                    newKpiList[index].additional_context_data = result.additional_context_data;
                    newKpiList[index].additional_context_metadata = result.additional_context_metadata;
                    setKpiList(newKpiList);
                }
            }).catch((error) => {
                console.error("Error: " + error);
            });
        }
    };

    /**
     * While removing KPI through discard button
     * @param {*} index 
     */
    const handleRemoveKPI = (index) => {
        const selectedAddress = kpiList[index].range;
        //  Clearing any range valdation errors
        const vSelectedRangeErrors = { ...selectedRangeErrors };
        const vSelectedRangeError = errorMessageDuplicateRange;
        if (vSelectedRangeErrors[index]) {
            delete vSelectedRangeErrors[index];
        }
        const rangeErrorKeys = Object.keys(vSelectedRangeErrors);
        const vRangeErrorIndex = rangeErrorKeys.find((element) => ((vSelectedRangeErrors[element] === vSelectedRangeError) && (element !== index.toString())));
        if ((typeof(vRangeErrorIndex) !== "undefined") && (vRangeErrorIndex !== null) && (vRangeErrorIndex !== -1)) {
            if (selectedAddress === kpiList[vRangeErrorIndex].range) {
                delete vSelectedRangeErrors[vRangeErrorIndex];
            }
        }
        setSelectedRangeErrors(vSelectedRangeErrors);
        const newKpiList = kpiList.filter((_, i) => i !== index);
        setKpiList(newKpiList);
        if (!newKpiList.length) {
            setSelectedDropdown('')
        }
        validateFields(newKpiList);
    };

    /**
      * Function to close a Delete KPIs/Metrics modal.
      */
    const handleCloseDACModal = () => {
        setShowDACModal(false)
    }

    /**
      * Function to delete a Delete KPIs/Metrics.
      */
    const handleDelete = async () => {
        setDisableConfirmButton(true);
        const responseStatus = await tableService.deleteADC(projectId, selectedTable.id, selectedKpiId);
        setDisableConfirmButton(false);
        if (responseStatus === 200) {
            setShowDACModal(false);
            setShowDACToast(true)
            getAdditionalContext();
        }
    };

    /**
      * Function will be called to get KPIs/Metrics.
      */
    const getAdditionalContext = async () => {
        const vData = {
            "projectId": projectId,
            "tableId": selectedTable.id
        };
        const response = await tableService.getAdditionalContext(vData);
        if (response) {
            loadAdditionalContext(response);
            setAddConViewResponseData(response);
        }
    }

    /**
     * Function to check whether composition already exists for selected rowitem
     */
    const compositionCheckForRowItems = (data) => {
        if (addConViewResponseData) {
            const originRowIdData = addConViewResponseData.filter(item => item.nature === "Composition")
                .map(item => item.originRowId);

            // Check if selectedTableRow exists in originRowIdData
            if (originRowIdData.includes(data)) {
                getReferences(true);
            } else {
                getReferences(false);
            }
        }
    }

    /**
     * Function to load existing KPIs/Metrics data during popup load
     */
    const loadAdditionalContext = (vData) => {
        if (vData) {
            const uniqueRowId = [...new Set(vData.map(item => item.originRowId))];
            for (let i in uniqueRowId) {
                additionalContextList.push({
                    id: uniqueRowId[i],
                    name: selectedTable.tableContent.find(item => item.rowId === uniqueRowId[i])?.RowHeader,
                })
            }
            setAdditionalContextRowHeaderList(additionalContextList);
            let arrayList = [];
            for (let i in vData) {
                arrayList.push({
                    originRowId: vData[i].originRowId,
                    kpiId: vData[i].kpiId,
                    kpiUploadRange: `'${vData[i].kpimetadata.sheetName}'!${vData[i].kpimetadata.uploadRange}`,
                    kpiNature: vData[i]?.nature,
                    Description: vData[i]?.nature_description,
                })
            }
            setAdditionalContexttableList(arrayList);
        }
    }

    /**
   * This assists in configuring column headers.
   */
    const colDefs = [
        {
            headerName: "Nature of Input",
            wrapText: true,
            autoHeight: true,
            flex: 2,
            type: 'leftAligned',
            cellClass: 'Nature-column',
            filter: false,
            sortable: false,
            cellRenderer: (params) => {
                return (
                        <div>
                            {params.data.kpiNature}
                            {(params.data.kpiNature === "KPI" || params.data.kpiNature === "Additional Data") &&
                                <MotifIconButton>
                                    <div className='icon-Info-circle' >
                                        <MotifTooltip
                                            size="sm"
                                            trigger={<img src={infoCircle} alt="info" className='icon-circle' />}
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            {params.data.Description}
                                        </MotifTooltip>
                                    </div>
                                </MotifIconButton>
                            }
                        </div>
                );
            },
        },
        {
            headerName: "Table range",
            field: "kpiUploadRange",
            wrapText: true,
            autoHeight: true,
            flex: 2,
            type: 'centerAligned',
            cellClass: 'relation-column',
            filter: false,
            sortable: false
        },
        {
            field: "",
            headerName: "Actions",
            wrapText: true,
            autoHeight: true,
            width: 120,
            cellRenderer: (params) => {
                return (
                    <MotifIconButton
                        aria-label="Delete"
                        className="btnDelete"
                        onClick={() => handleOpenModal(params.data.kpiId)}
                    >
                        <img className='trashrelation' src={iconTrash} alt="Delete" />
                    </MotifIconButton>
                );
            },
            cellClass: 'relation-column-center',
            colId: "actions",
            filter: false,
            sortable: false
        }
    ];

    /**
     * Function will be called when submitting add KPIs/Metrics.
     */
    const addAdditionalContext = async () => {
        setDisableConfirmButton(true);
        const vDataArr = [];
        const vReversedKPIList = [...kpiList].reverse();
        for (const element of vReversedKPIList) {
            vDataArr.push({
                "tableId": selectedTable.id,
                "rowId": selectedTableRow,
                "additional_context_data": element.additional_context_data,
                "additional_context_metadata": element.additional_context_metadata,
                "nature": selectedDropdown,
                "nature_description": element.description,
                "userId": vCurrentUserEmail
            })
        }
        const vData = {
            "reqdata_list": vDataArr
        };
        const response = await tableService.addAdditionalContext(vData);
        if (response) {
            clearAllFields();
            setDisableConfirmButton(false);
            getAdditionalContext();
            gotoStep(1);
            setShowEditToast(true);
            setShowClose(true);
        } else {
            setDisableConfirmButton(false);
            closeModal('Failure', response?.output);
        }
    };

    /**
     * While changing Nature of Input field
     * @param {*} e 
     */
    const handleDropDownChange = (e) => {
        const selectedValue = e;
        if ((selectedValue !== "") && (selectedValue !== selectedDropdown)) {
            setSelectedDropdown(selectedValue);
            setKpiList([{ description: '', range: '' }]);
            setErrors({});
            setSelectedRangeErrors({});
        }
    }

    /**
     * Function to set selected table row or line item
     * @param {*} data 
     */
    const onRowChange = (data) => {
        setSelectedTableRow(data);
        compositionCheckForRowItems(data);
        setKpiList([]);
        setSelectedDropdown('');
    }

    /**
     * Function to switch Step
     * Also currently integrated auto suggestions for table details.
     * @param {*} vStep 
     */
    const gotoStep = (vStep) => {
        setCurrentStep(vStep);
    }

    /**
     * Validate selected table range
     * @param {*} vRange 
     */
    const validateSelectedRange = (vRange, sheetName, vAddress, vIndex) => {
        //  Check for minimum 1 row selected
        if (vRange.length <= 0) {
            if (selectedDropdown === 'Composition') {
                return infoMessageRangeDefaultComposition;
            } else {
                return infoMessageRangeDefaultGeneric;
            }
        }

        // Check for same sheet only for Composition
        if ((selectedDropdown === 'Composition') && (sheetName !== selectedTable?.metadata?.sheetName)) {
            return 'Selection must be from the same table';
        }

        // Check for same no of columns as parent table selected
        if (vRange[0].length !== selectedTable.tableHeaders.length) {
            if (selectedDropdown === 'Composition') {
                return 'Periods selected must match periods for row items';
            } else {
                return infoMessageRangeDefaultGeneric;
            }
        }

        // Check for Sum of the selection in each period must match the value of the selected row
        if ((selectedDropdown === 'Composition') && (vRange.length >= 1) && vRange.every((arr) => arr.length > 1)) {
            for (const element of selectedTable.tableContent) {
                if (element.rowId === selectedTableRow) {
                    let mainRowItemvalues = Object.values(element).filter((value) => typeof value === "number");

                    // Round the mainRowItemvalues
                    let roundedMainRowItemvalues = mainRowItemvalues.map(value => Math.round(value));

                    // Iterate through each period and check the sum
                    for (let periodIndex = 1; periodIndex < vRange[0].length; periodIndex++) {
                        let sumValue = vRange.reduce((sum, arr) => sum + arr[periodIndex], 0);

                        // Round the sumValue
                        let roundedSumValue = Math.round(sumValue);

                        if (roundedSumValue !== roundedMainRowItemvalues[periodIndex - 1]) {
                            return "Sum of the selection in each period must match the value of the selected row";
                        }
                    }
                }
            }
        }

        //  Check if first cell from each rows are not empty
        for (const element of vRange) {
            if (element[0] === "") {
                if (selectedDropdown === 'Composition') {
                    return infoMessageRangeDefaultComposition;
                } else {
                    return infoMessageRangeDefaultGeneric;
                }
            }
        }

        const vAdditionalContextList = additionalContexttableList.filter(element => (element.originRowId === selectedTableRow));
        const isAddressExists = vAdditionalContextList.findIndex(element => (element.kpiUploadRange === vAddress));
        if (isAddressExists !== -1) {
            return errorMessageDuplicateRange;
        }
        let hasDuplicateAddress = false;
        for(let i=0; i<kpiList.length; i++) {
            if ((i !== vIndex) && (kpiList[i].range === vAddress) && (!selectedRangeErrors[i])) {
                hasDuplicateAddress = true;
                break;
            }
        }
        if (hasDuplicateAddress) {
            return errorMessageDuplicateRange;
        }
        return null;
    };

    /**
     * Funxtion to sort array descending order
     * @param {*} vArr 
     * @param {*} vField 
     * @returns 
     */
    const sortByDesc = (vArr, vField) => {
        return vArr.sort((a, b) => {
            if (a[vField] > b[vField]) { return -1; }
            if (b[vField] > a[vField]) { return 1; }
            return 0;
        });
    };

    /**
     * Function to clear all fields after back and confirm actions
     */
    const clearAllFields = () => {
        setSelectedTableRow('');
        setSelectedDropdown('');
        setKpiList([]);
    };

    return (
        <>
            {showDACModal && (
                <DeleteAdditionalContext
                    onClose={handleCloseDACModal}
                    handleDelete={handleDelete}
                    disableConfirmButton={disableConfirmButton}
                />
            )}
            <MotifModal
                show={setOpenModal}
                onClose={() => setOpenModal(!openModal)}
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
                className='TableRelationshipModal genericModal modalMotifFooterModal'
                size='xl'
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className={currentStep === 2 ? 'addTableRelationshipHeader' : 'addTableRelationshipHeader remove-border'}>
                    <div className='addTableRelationshipHead'>
                        {(currentStep === 1) ? `KPIs/Metrics for ${selectedTable.tableName}` : `Add KPIs/Metrics for ${selectedTable.tableName}`}
                    </div>
                </MotifModalHeader>
                <MotifModalBody className={((currentStep === 1) && !additionalContextRowHeaderList?.length) ? 'modalBodyAdditionalContext' : ''}>
                    {(currentStep === 1) &&
                        <>
                            {((
                                (!additionalContextRowHeaderList) ||
                                (additionalContextRowHeaderList?.length === 0)
                            ) &&
                                showEditToast === false
                            ) &&
                                <div className='bodycenterdiv'>
                                    <div className='bodycenterinnerdiv'>
                                        <img className='imageborder' src={iconNoRelationship} alt="Previous" />
                                    </div>
                                    <div className='bodycenterinnerdivtextkpi'>
                                        No KPIs/Metrics found
                                    </div>
                                    <div className='bodycenterinnerdivbottomtext'><p>To add KPI, Composition and Additional Data,<br /> click
                                        <mark className='addrelation'> 'Add KPIs/Metrics'</mark></p>
                                        <div className='tableRelationshipNoKPI'>
                                            {(currentStep === 1) &&
                                                <MotifIconButton
                                                    className='add-relationshipkpi'
                                                    aria-label="Add KPIs/Metrics"
                                                    onClick={() => {
                                                        gotoStep(2);
                                                        setShowClose(false);
                                                    }}
                                                >
                                                    <img className='addrelationicon' src={iconAddWhite} alt="Previous" />
                                                    <p className='add-relationkpi'>Add KPIs/Metrics</p>
                                                </MotifIconButton>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {((additionalContextRowHeaderList?.length > 0) ||
                                (additionalContextRowHeaderList?.length === 0 && showEditToast)) &&
                                <div className='bodyheaderdiv'>
                                    <div className='lblSelectTableName'>
                                        <div className='relation-accordian'>
                                            {additionalContextRowHeaderList?.map((vLineItem) => (
                                                <MotifAccordion
                                                    id="relationaccordian"
                                                    key={vLineItem.id}
                                                    onOpen={() => { }}
                                                    style={{
                                                        flex: '1'
                                                    }}
                                                    useChevronIcon={true}
                                                    className='relaccordian'
                                                >
                                                    <MotifAccordionTrigger>
                                                        {vLineItem.name}
                                                    </MotifAccordionTrigger>
                                                    <MotifAccordionContent>
                                                        {additionalContexttableList &&
                                                            <MotifTable
                                                                rowData={sortByDesc(additionalContexttableList.filter(item => (item.originRowId === vLineItem.id)), 'kpiNature')}
                                                                columnDefs={colDefs}
                                                                suppressCellFocus
                                                                suppressRowClickSelection
                                                                suppressRowHoverHighlight
                                                                enableSorting={false}
                                                                className='tableAdditionalContext'
                                                            ></MotifTable>
                                                        }
                                                    </MotifAccordionContent>
                                                </MotifAccordion>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {(currentStep === 2) &&
                        <>
                            <div className='lblSelectRow'>
                                Select Row
                            </div>
                            <div className='fldRowContainer'>
                                <div className='fldRow'>
                                    <MotifFormField>
                                        <MotifSelect
                                            value={selectedTableRow}
                                            onChange={val => onRowChange(val)}
                                            ariaLabelledBy="Table Row"
                                            placeholder='Select row'
                                            triggerButtonProps={{
                                                'aria-describedby': 'Table Row'
                                            }}
                                            visibleOptions={3}>
                                            {
                                                (selectedTable) ?
                                                    selectedTable?.tableContent?.map((element) =>
                                                        <MotifOption value={element.rowId} key={element.rowId}>{element.RowHeader}</MotifOption>
                                                    ) :
                                                    <MotifOption value="" key="0">Select Row</MotifOption>
                                            }
                                        </MotifSelect>
                                        <MotifLabel className='infoText'>
                                            Select 1 row to add KPIs/Metrics
                                        </MotifLabel>
                                    </MotifFormField>
                                </div>
                            </div>
                            <div className='fldNature'>
                                <MotifFormField>
                                    <MotifLabel className='fldLabel'>
                                        Nature of Input
                                    </MotifLabel>
                                    <MotifSelect
                                        value={selectedDropdown}
                                        onChange={handleDropDownChange}
                                        ariaLabelledBy="Nature of input"
                                        placeholder='Select nature of input'
                                        triggerButtonProps={{
                                            'aria-describedby': 'Nature of input'
                                        }}
                                        visibleOptions={3}>
                                        {natureOfInputRefs?.map((element, index) =>
                                            <MotifOption value={element.ReferenceValue} key={element.ReferenceValue}>
                                                {element.ReferenceKey}</MotifOption>
                                        )}
                                    </MotifSelect>
                                </MotifFormField>
                            </div>
                            {(selectedDropdown) && (
                                <>
                                    {
                                        (selectedDropdown !== 'Composition') && (
                                            <div className="AddKPI_Btn_container">
                                                <MotifButton
                                                    variant="secondary"
                                                    className="AddKPI_Btn"
                                                    onClick={handleAddKPI}
                                                    disabled={isAddButtonDisabled}
                                                >
                                                    <div className="AddKPIbuttoon">
                                                        {
                                                            isAddButtonDisabled ?
                                                                <img src={iconAddKPI} alt={`Add ${selectedDropdown}`} /> :
                                                                <img src={iconAddKPIBlue} alt={`Add ${selectedDropdown}`} />
                                                        }
                                                        {
                                                            isAddButtonDisabled ?
                                                                <span className="Add_KPI_Btn_text">{`Add ${selectedDropdown}`}</span> :
                                                                <span className="Add_KPI_Btn_Blue_text">{`Add ${selectedDropdown}`}</span>
                                                        }
                                                    </div>
                                                </MotifButton>
                                            </div>
                                        )
                                    }
                                    {kpiList.map((kpi, index) => (
                                        <div key={kpi.index} className="kpiitem">
                                            {
                                                (selectedDropdown !== 'Composition') && (
                                                    <>
                                                        <div className="kpiheader">
                                                            <div className='KPI-number'>{selectedDropdown} {kpiList.length - index}</div>
                                                            <div className="Discard_Btn_container">
                                                                <MotifButton
                                                                    variant="secondary"
                                                                    className="DiscardKPI_Btn"
                                                                    onClick={() => handleRemoveKPI(index)}
                                                                >
                                                                    <div className="DiscardKPIbuttoon">
                                                                        <img src={iconDiscardKPI} alt={`Discard ${selectedDropdown}`} className='DiscardKPIImage' />
                                                                        <span className="Discard_KPI_Btn_text">Discard</span>
                                                                    </div>
                                                                </MotifButton>
                                                            </div>
                                                        </div>
                                                        <div className="fldNature">
                                                            <MotifFormField>
                                                                <MotifLabel className="fldLabel">Input Description</MotifLabel>
                                                                <MotifInput
                                                                    onChange={(e) => onInputDescChange(index, e.target.value)}
                                                                    type="text"
                                                                    value={kpi.description}
                                                                    placeholder="Enter description"
                                                                    className={errors[index] ? 'motif-input-invalid' : ''}
                                                                    hideClearButton={!!errors[index]}
                                                                    onBlur={() => setErrorMessageCharLimit(infoCharLimitGeneric)}
                                                                />
                                                                {errors[index] && (
                                                                    <>
                                                                        <img src={iconError} alt="Error" className="iconError topPosNature" />
                                                                        <MotifErrorMessage className="errorText">
                                                                            <div>{errors[index]}</div>
                                                                        </MotifErrorMessage>
                                                                    </>
                                                                )}
                                                                {!errors[index] && (
                                                                    <div className="infoText">
                                                                        {errorMessageCharLimit}
                                                                    </div>
                                                                )}
                                                            </MotifFormField>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className="lblSelectRange">Select Range</div>
                                            <div className="fldRange">
                                                <MotifFormField className={selectedRangeErrors[index] ? 'field-group error-border' : 'field-group'}>
                                                    <MotifInput
                                                        value={kpi.range}
                                                        placeholder="Add range"
                                                        onFocus={() => getSelectedRange(index)}
                                                        className={selectedRangeErrors[index] ? 'inputThreshold motif-input-invalid' : 'inputThreshold'}
                                                        data-testid="testIdFldRange"
                                                        hideClearButton
                                                        readOnly
                                                    />
                                                    {selectedRangeErrors[index] && (
                                                        <MotifTooltip
                                                            contentClassName="tooltipWithoutCloseCenterText"
                                                            trigger={<img src={iconError} alt="Error" className="helpTooltipIcon" />}
                                                            placement="top"
                                                            allowClick={false}
                                                            hideCloseButton
                                                        >
                                                            Please select a <br />valid range
                                                        </MotifTooltip>
                                                    )}
                                                </MotifFormField>
                                                {!selectedRangeErrors[index] && (
                                                    <MotifLabel className="infoText">
                                                        {
                                                            infoMessageRangeDefaultComposition
                                                        }
                                                    </MotifLabel>
                                                )}
                                                {selectedRangeErrors[index] && (
                                                    <MotifErrorMessage className="rangeErrorMessage errorText">
                                                        {selectedRangeErrors[index]}
                                                    </MotifErrorMessage>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    }

                </MotifModalBody>
                <MotifModalFooter className='TableRelationshipFooter'>
                    <div className='additionalContextToastLayer'>
                        {showDACToast && (
                            <MotifToast
                                actionName=""
                                onClose={() => {
                                    setShowDACToast(false);
                                }}
                                variant="info"
                                className='notifyToast11'
                                icons={{ toast: <img src={iconWhiteCheckCircle} alt="Info" /> }}
                            >
                                KPIs/Metrics removed
                            </MotifToast>
                        )}
                        {(showEditToast) &&
                            <MotifToast
                                actionName=""
                                onClose={() => {
                                    setShowEditToast(false);
                                }}
                                variant={"success"}
                                className='notifyToast'
                                icons={{ toast: <img src={iconSuccess} alt="Success" /> }}
                            >
                                KPIs/Metrics added successfully
                            </MotifToast>
                        }
                    </div>
                    <div className='tableRelationshipFooterleftkpi'>
                        {(currentStep === 1 && (additionalContextRowHeaderList?.length > 0)) &&
                            <MotifIconButton
                                className='add-relationshipkpi'
                                aria-label="Add KPIs/Metrics"
                                onClick={() => {
                                    gotoStep(2);
                                    setShowClose(false);
                                }}
                            >
                                <img className='addrelationicon' src={iconAddWhite} alt="Previous" />
                                <p className='add-relationkpi'>Add KPIs/Metrics</p>
                            </MotifIconButton>
                        }
                    </div>
                    <div className='tableRelationshipFooterleftkpiback'>
                        {(currentStep === 2) &&
                            <MotifButton
                                aria-label="Click here to go back to 'KPIs/Metrics' popover"
                                onClick={() => {
                                    clearAllFields();
                                    gotoStep(1);
                                }}
                                className='btnBackKPI'
                                data-testid="testIdBtnBack"
                                variant='text'
                            >
                                <img src={iconArrowBack} alt='Previous' />
                                <span className='back-arrow'>Back</span>
                            </MotifButton>
                        }
                    </div>
                    <div className='tableRelationshipFooterRight'>
                        <MotifButton
                            onClick={() => setOpenModal(!openModal)}
                            type="button"
                            variant="secondary"
                            className='btnCancel'
                        >
                            {showClose ? "Close" : "Cancel"}
                        </MotifButton>
                        {(currentStep === 2) &&
                            <MotifButton
                                onClick={() => addAdditionalContext()}
                                type="button"
                                className='btnConfirm'
                                disabled={
                                    disableConfirmButton ||
                                    isAddButtonDisabled ||
                                    !selectedTableRow ||
                                    !selectedDropdown ||
                                    !kpiList.length ||
                                    isInValidSelectedRange ||
                                    isInValidDescription
                                }
                            >
                                Confirm
                            </MotifButton>
                        }
                    </div>
                </MotifModalFooter>
            </MotifModal>
        </>
    );
};

AdditionalContext.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    closeModal: PropTypes.func,
    selectedTable: PropTypes.object
};

export default AdditionalContext;