/**
 * Add table modal
 * Created On: 24-06-2024
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

//  Motif controls
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import MotifButton, { MotifIconButton } from "@ey-xd/motif-react/Button";
import MotifFormField from "@ey-xd/motif-react/FormField";
import {
  MotifInput,
  MotifMessage,
  MotifErrorMessage,
  MotifLabel,
} from "@ey-xd/motif-react";
import MotifSelect, { MotifOption } from "@ey-xd/motif-react/Select";

import "./AddTable.scss";
import TableService from "../../../../services/tableService";
import iconError from "../../../../assets/images/error.svg";
import iconEdit from "../../../../assets/images/edit.svg";
import EditRangeWarning from "./EditRangeWarning";
import CommonService from "../../../../services/commonService";

const AddTable = ({
  openModal,
  setOpenModal,
  projectId,
  projectName,
  tables,
  closeModal,
  isAddClicked,
  selectedTable,
}) => {
  //  Error Messages
  const errMsgAlphaNumWithMax50Chars =
    "Please enter alphanumeric name, up to 50 characters.";
  const errMsgDuplicateTable =
    "Table name already in use, choose another name.";
  const [currentStep, setCurrentStep] = useState(1);
  const [disableAddTableButton, setDisableAddTableButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState("");
  const [tableName, setTableName] = useState("");
  const [oldTableName, setOldTableName] = useState("");
  const [tableType, setTableType] = useState("");
  const [frequency, setFrequency] = useState("");
  const [currency, setCurrency] = useState("");
  const [tableData, setTableData] = useState({});
  const [isErrorOnRange, setIsErrorOnRange] = useState(false);
  const [isErrorOnDuplicateRange, setIsErrorOnDuplicateRange] = useState(false);
  const [isErrorOnTableName, setIsErrorOnTableName] = useState(false);
  const [errorMsgTableName, setErrorMsgTableName] = useState("");
  const [isErrorOnCurrency, setIsErrorOnCurrency] = useState(false);
  const [tableTypeRefs, setTableTypeRefs] = useState([]);
  const [frequencyRefs, setFrequencyRefs] = useState([]);
  const [selectedFirstCell, setSelectedFirstCell] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { activeProject } = useSelector((state) => state.user);
  const [isTableNameChangedAlone, setIsTableNameChangedAlone] = useState(false);
  const vCurrentUserEmail = user?.username;
  const vCurrentUserRole = "write";

  const tableService = new TableService();
  const commonService = new CommonService();
  const [show, setShow] = useState(false);
  const [unit, setUnit] = useState("");
  const [isErrorOnUnit, setIsErrorOnUnit] = useState(false);

  /**
   * Hook to populate table type references
   */
  useEffect(() => {
    const getReferences = async () => {
      const response = await commonService.getReferences();
      if (response) {
        const vTableTypeRefs = response.filter(
          (element) => element.GroupKey === "TableType"
        );
        setTableTypeRefs(vTableTypeRefs);
        const vFrequencyRefs = response.filter(
          (element) => element.GroupKey === "Frequency"
        );
        setFrequencyRefs(vFrequencyRefs);
      }
    };
    getReferences();
    // eslint-disable-next-line
  }, []);

  /**
   * Hook to populate selected range initially
   */
  useEffect(() => {
    if (isAddClicked) {
      getSelectedRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName, tableType, frequency, currency, unit]);

  /**
   * Hook to handle to set old data in edit scenario
   */
  useEffect(() => {
    if (!isAddClicked) {
      setCurrentStep(2);
      setPreviousData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to set previous table related data
   */
  const setPreviousData = () => {
    if (selectedTable) {
      setCurrency(selectedTable.currency);
      setUnit(selectedTable.unit);
      setSelectedRange(
        `'${selectedTable.metadata.sheetName}'!${selectedTable.metadata.uploadRange}`
      );
      setTableName(selectedTable.tableName);
      setOldTableName(selectedTable.tableName);
      setTableType(selectedTable.tableType);
      setFrequency(selectedTable.frequency);
    }
  };

  /**
   * Description
   * -----------
   * This method is show popup to user for closing project
   * Parameters:
   * isvisible: This parameter is to show close popup
   */
  const showPopUp = (isvisible) => {
    setShow(isvisible);
  };

  /**
   * Description
   * -----------
   * This method is get confirmation from user for closing project
   */
  const confirmMenuClick = async () => {
    let vMetadata = selectedTable.metadata;
    let vSelectedRange = selectedRange;
    if (selectedRange.indexOf("!") !== -1) {
      vSelectedRange = selectedRange.split("!")[1];
    }
    let result = {};
    result["currency"] = currency;
    result["unit"] = unit;
    result["frequency"] = frequency;
    result["tableId"] = selectedTable.id;
    result["table_name"] = tableName;
    result["table_type"] = tableType;
    result["userId"] = vCurrentUserEmail;
    result["userRole"] = vCurrentUserRole;
    result["projectId"] = projectId;
    result['table_name_changed'] = isTableNameChangedAlone;
    if (vMetadata.uploadRange !== vSelectedRange) {
      const vMetadata = tableData.metadata;
      vMetadata["createdAt"] = selectedTable.metadata.createdAt;
      vMetadata["createdBy"] = selectedTable.metadata.createdBy;
      result["metadata"] = vMetadata;
      result["table_content"] = tableData.table_content;
      result["table_headers"] = tableData.table_headers;
      result["table_relationships"] = [];
      result["template_mappings"] = [];
    } else {
      result["metadata"] = selectedTable.metadata;
      result["table_content"] = selectedTable.tableContent;
      result["table_headers"] = selectedTable.tableHeaders;
      result["table_relationships"] = selectedTable.tableRelationships;
      result["template_mappings"] = selectedTable.templateMappings;
    }
    if (result) {
      setTableData(result);
      setDisableAddTableButton(true);
      const response = await tableService.editTable(result);
      if (response && response.status && response.status === 200) {
        setDisableAddTableButton(false);
        closeModal("Success", null, false, isTableNameChangedAlone);
        setShow(false);
      } else {
        setDisableAddTableButton(false);
        closeModal("Failure", response, false);
      }
    }
  };

  /**
   * Function to get selected table range in excel
   */
  const getSelectedRange = () => {
    if (window.Excel) {
      window.Excel.run(async (context) => {
        // Get the selected range
        const selectedRange = context.workbook.getSelectedRange();
        selectedRange.load(["values", "address", "worksheet/name"]);

        // Sync the context to load the selected range properties
        await context.sync();
        // Extract the values and address from the selected range
        const values = selectedRange.values;
        validateDuplicateRange(selectedRange);
        validateSelectedRange(values);

        if (
          selectedRange?.address &&
          selectedRange.address.indexOf(":") !== -1
        ) {
          setSelectedRange(selectedRange.address);
        }
        const address = selectedRange.address
          .replace(selectedRange.worksheet.name, "")
          .replace(/[&#,+()$~%.'"*?!<>{}]/g, "");

        const sheetName = selectedRange.worksheet.name;
        // Modify the first cell to 'RowHeader'

        setSelectedFirstCell(values[0][0]);
        values[0][0] = "RowHeader";
        // Extract keys from the header row
        const keys = values[0];
        const vRegExp = /[\r\n]/gm;
        const table_headers = keys.map((item) =>
          typeof item === "string" ? item.replace(vRegExp, "") : item.toString()
        );

        // Process the table values to create a list of dictionaries
        const tableContent = values.slice(1).map((record) => {
          return keys.reduce((dict, key, index) => {
            dict[
              typeof key === "string"
                ? key.replace(vRegExp, "")
                : key.toString()
            ] = record[index];
            return dict;
          }, {});
        });

        // Extract the workbook name (filename)
        const workbook = context.workbook;
        workbook.load("name");
        await context.sync();
        const workbookName = workbook.name;

        // Prepare the result object with values and metadata
        const result = {
          table_name: tableName,
          table_content: tableContent,
          table_headers,
          metadata: {
            filename: workbookName,
            sheetName: sheetName,
            uploadRange: address,
          },
          table_type: tableType,
          userId: vCurrentUserEmail,
          userRole: vCurrentUserRole,
          projectId: projectId,
          frequency: frequency,
          currency: currency,
          unit: unit,
        };
        if (result) {
          setTableData(result);
        }
      }).catch((error) => {
        console.error("Error: " + error);
      });
    }
  };

  /**
   * Validate selected table range
   * @param {*} vRange
   */
  const validateSelectedRange = (vRange) => {
    //  Check for minimum 2 rows selected
    if (vRange.length > 1) {
      setIsErrorOnRange(false);
    } else {
      setIsErrorOnRange(true);
      return;
    }
    //  Check for minimum 3 columns selected
    if (vRange[0].length > 2) {
      setIsErrorOnRange(false);
    } else {
      setIsErrorOnRange(true);
      return;
    }
    //  Check if first cell from first 2 rows are not empty
    if (vRange[0][0] !== "" && vRange[1][0] !== "") {
      setIsErrorOnRange(false);
    } else {
      setIsErrorOnRange(true);
    }
  };

  /**
   * Validate selected table range
   * @param {*} vAddress
   */
  const validateDuplicateRange = (vSelectedRange) => {
    //  Code for Range Validation
    if (tables) {
      const vAddress = vSelectedRange.address
        .replace(vSelectedRange.worksheet.name, "")
        .replace(/[&#,+()$~%.'"*?!<>{}]/g, "");
      const vSheetName = vSelectedRange.worksheet.name;

      const filteredTablerange = tables.filter(
        (element) =>
          element.metadata.sheetName === vSheetName &&
          element.metadata.uploadRange === vAddress
      );
      if (!!isAddClicked && filteredTablerange.length > 0) {
        setIsErrorOnDuplicateRange(true);
        setDisableAddTableButton(true);
        return;
      }
      if (!isAddClicked && filteredTablerange.length > 0) {
        setIsErrorOnDuplicateRange(true);
        setDisableAddTableButton(true);
      } else {
        setIsErrorOnDuplicateRange(false);
        setDisableAddTableButton(false);
      }
    }
  };

  /**
   * Function to switch Step
   * Also currently integrated auto suggestions for table details.
   * @param {*} vStep
   */
  const gotoStep = (vStep) => {
    if (vStep === 1) {
      getSelectedRange();
    } else if (vStep === 2) {
      getAutoSuggestedTableDetails();
    }
    setCurrentStep(vStep);
  };

  /**
   * Add table
   */
  const addTable = async () => {
    let vData = tableData;
    vData.globalThresholdValue = parseInt(activeProject?.globalThresholdValue);
    vData.globalThresholdPercent = parseInt(
      activeProject?.globalThresholdPercent
    );
    setDisableAddTableButton(true);
    const response = await tableService.addTable(vData);
    if (response && response.status && response.status === 200) {
      setDisableAddTableButton(false);
      closeModal("Success", null, true);
    } else {
      setDisableAddTableButton(false);
      closeModal("Failure", null, true);
    }
  };

  /**
   * Function to populate table name to table data
   * @param {*} data
   */
  const onTableNameChange = (data) => {
    setTableName(data);
    data = data ? data.trim() : "";
    //  Check for Alphanumeric with space & maximum 50 characters validation
    const vValid = /^[a-zA-Z0-9 ]*$/.test(data);
    if (data === "" || !vValid || data.length > 50) {
      setIsErrorOnTableName(true);
      setErrorMsgTableName(errMsgAlphaNumWithMax50Chars);
      setDisableAddTableButton(true);
      return;
    }
    const filteredTable = tables.filter(
      (element) => element.tableName.toLowerCase() === data.toLowerCase()
    );
    if (
      !(!isAddClicked && data.toLowerCase() === oldTableName.toLowerCase()) &&
      filteredTable.length > 0
    ) {
      setIsErrorOnTableName(true);
      setErrorMsgTableName(errMsgDuplicateTable);
      setDisableAddTableButton(true);
      return;
    }
    setIsErrorOnTableName(false);
    setErrorMsgTableName("");
    setDisableAddTableButton(false);
    let vTableData = tableData;
    vTableData.table_name = data;
    setTableData(vTableData);
  };

  /**
   * Function to populate table type to table data
   * @param {*} data
   */
  const onTableTypeChange = (data) => {
    setTableType(data);
    let vTableData = tableData;
    vTableData.table_type = data;
    setTableData(vTableData);
  };

  /**
   * Function to populate frequency to table data
   * @param {*} data
   */
  const onFrequencyChange = (data) => {
    setFrequency(data);
    let vTableData = tableData;
    vTableData.frequency = data;
    setTableData(vTableData);
  };

  /**
   * Function to populate currency to table data
   * @param {*} data
   */
  const onCurrencyChange = (data) => {
    setCurrency(data);
    data = data ? data.trim() : "";
    //  Check for Alphanumeric with space & maximum 50 characters validation
    const vValid = /^[a-zA-Z0-9 ]*$/.test(data);
    if (data === "" || !vValid || data.length > 50) {
      setIsErrorOnCurrency(true);
      setDisableAddTableButton(true);
      return;
    }
    setIsErrorOnCurrency(false);
    setDisableAddTableButton(false);
    let vTableData = tableData;
    vTableData.currency = data;
    setTableData(vTableData);
  };

  /**
   * Function to populate unit to table data
   * @param {*} data
   */
  const onUnitChange = (data) => {
    setUnit(data);
    data = data ? data.trim() : "";
    //  Check for Alphanumeric with space & maximum 50 characters validation
    const vValid = /^[a-zA-Z0-9 ]*$/.test(data);
    if (data === "" || !vValid || data.length > 50) {
      setIsErrorOnUnit(true);
      setDisableAddTableButton(true);
      return;
    }
    setIsErrorOnUnit(false);
    setDisableAddTableButton(false);
    let vTableData = tableData;
    vTableData.unit = data;
    setTableData(vTableData);
  };

  /**
   * Function to get auto suggestions for table details
   */
  const getAutoSuggestedTableDetails = async () => {
    let vTableData = tableData;
    if (selectedFirstCell) {
      vTableData = JSON.parse(JSON.stringify(tableData));
      vTableData.table_headers[0] = selectedFirstCell;
    }
    const response = await tableService.getAutoSuggestedTableDetails(
      vTableData
    );
    if (response) {
      setTableName(response.tableName);
      setTableType(response.tableType);
      setFrequency(response.frequency);
      setCurrency(response.currency);
      setUnit(response.unit);
    }
  };

  /**
   * Hook to check if old values changed
   */
  useEffect(() => {
    if (!isAddClicked) {
        setDisableAddTableButton(
            (selectedRange === `'${selectedTable.metadata.sheetName}'!${selectedTable.metadata.uploadRange}`) &&
            (tableName.trim() === selectedTable.tableName) &&
            (tableType.trim() === selectedTable.tableType) &&
            (frequency.trim() === selectedTable.frequency) &&
            (currency.trim() === selectedTable.currency) &&
            (unit.trim() === selectedTable.unit)
        )
        setIsTableNameChangedAlone(
            (selectedRange === `'${selectedTable.metadata.sheetName}'!${selectedTable.metadata.uploadRange}`) &&
            (tableName.trim() !== selectedTable.tableName) &&
            (tableType.trim() === selectedTable.tableType) &&
            (frequency.trim() === selectedTable.frequency) &&
            (currency.trim() === selectedTable.currency) &&
            (unit.trim() === selectedTable.unit)
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedRange, tableName, tableType, frequency, currency, unit]);

    /**
     * Function to display confirmation or call API directly to update table data
     */
    const updateTable = () => {
      if (isTableNameChangedAlone) {
          confirmMenuClick();
      } else {
          showPopUp(true);
      }
  }

  return (
    <MotifModal
      show={setOpenModal}
      onClose={() => setOpenModal(!openModal)}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      className="addTableModal genericModal modalMotifFooterModal"
      size="xl"
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Close",
          title: "Close",
        }}
        className="addTableHeader"
      >
        <div className="addTableHead">
          {isAddClicked === true ? "Add New Table" : "Edit Table"}
        </div>
        <div className="addTableSubHead">Step {currentStep} of 2</div>
      </MotifModalHeader>
      <MotifModalBody className="addTableModalBody">
        <div className="lblSelectRange">
          {currentStep === 1 ? "Select Range (click below)" : "Selected range"}
        </div>
        <div className="fldRangeContainer">
          <div className="fldRange">
            <MotifFormField>
              <MotifInput
                onChange={(e) => setSelectedRange(e.target.value)}
                value={selectedRange}
                placeholder="Add range"
                onFocus={() => (currentStep === 1 ? getSelectedRange() : null)}
                className={
                  isErrorOnRange || isErrorOnDuplicateRange
                    ? "motif-input-invalid"
                    : ""
                }
                icons={{
                  clear: (
                    <MotifIconButton
                      aria-label="Edit"
                      onClick={() => gotoStep(1)}
                    >
                      <img src={iconEdit} alt="Edit range" />
                    </MotifIconButton>
                  ),
                }}
                hideClearButton={
                  !!isErrorOnRange ||
                  !!isErrorOnDuplicateRange ||
                  currentStep === 1
                }
                clearButtonTitle="Edit range"
                data-testid="testIdFldRange"
                readOnly="true"
              />
              {currentStep === 1 &&
                !isErrorOnRange &&
                !isErrorOnDuplicateRange && (
                  <MotifMessage className="rangeMessage">
                    <div>Select at least:</div>
                    <div>(i) 2 rows (including the table header)</div>
                    <div>
                      (ii) 3 columns (including the description and 2 periods
                      minimum).
                    </div>
                  </MotifMessage>
                )}

              {currentStep === 1 && isErrorOnDuplicateRange && (
                <>
                  <img
                    src={iconError}
                    alt="Error"
                    className="iconError topPosRange"
                  />
                  <MotifErrorMessage className="rangeMessage errorText">
                    <div>
                      Table range already in use, please select a valid range
                    </div>
                  </MotifErrorMessage>
                </>
              )}
              {currentStep === 1 && isErrorOnRange && (
                <>
                  <img
                    src={iconError}
                    alt="Error"
                    className="iconError topPosRange"
                  />
                  <MotifErrorMessage className="rangeMessage errorText">
                    <div>Select at least:</div>
                    <div>(i) 2 rows (including the table header)</div>
                    <div>
                      (ii) 3 columns (including the description and 2 periods
                      minimum).
                    </div>
                  </MotifErrorMessage>
                </>
              )}
            </MotifFormField>
          </div>
        </div>
        {currentStep === 2 && (
          <>
            <div className="detailsTitle">Details</div>
            <div className="detailsContent">
              Please review auto populated inputs below, edit as required and
              click 'Add' once confirmed.
            </div>
            <div className="fldTableName">
              <MotifFormField>
                <MotifLabel className="fldLabel">Table Name</MotifLabel>
                <MotifInput
                  onChange={(e) => onTableNameChange(e.target.value)}
                  type="text"
                  value={tableName}
                  className={isErrorOnTableName ? "motif-input-invalid" : ""}
                  hideClearButton={!!isErrorOnTableName}
                />
                {isErrorOnTableName && (
                  <>
                    <img
                      src={iconError}
                      alt="Error"
                      className="iconError topPosTableName"
                    />
                    <MotifErrorMessage className="errorText">
                      <div>{errorMsgTableName}</div>
                    </MotifErrorMessage>
                  </>
                )}
                {!isErrorOnTableName && (
                  <div className="tableDetails">
                    Please enter alphanumeric name, up to 50 characters.
                  </div>
                )}
              </MotifFormField>
            </div>
            <div className="fldTableType">
              <MotifFormField>
                <MotifLabel className="fldLabel">Table Type</MotifLabel>
                {tableTypeRefs?.length > 0 && (
                  <MotifSelect
                    value={tableType}
                    onChange={(val) => onTableTypeChange(val)}
                    ariaLabelledBy="Table Type"
                    triggerButtonProps={{
                      "aria-describedby": "Table Type",
                    }}
                    visibleOptions={3}
                  >
                    {tableTypeRefs?.map((element, index) => (
                      <MotifOption value={element.ReferenceValue} key={index}>
                        {element.ReferenceKey}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                )}
              </MotifFormField>
            </div>
            <div className="fldFrequency">
              <MotifFormField>
                <MotifLabel className="fldLabel">Frequency</MotifLabel>
                {frequencyRefs.length > 0 && (
                  <MotifSelect
                    value={frequency}
                    onChange={(val) => onFrequencyChange(val)}
                    ariaLabelledBy="Frequency"
                    triggerButtonProps={{
                      "aria-describedby": "Frequency",
                    }}
                    visibleOptions={3}
                  >
                    {frequencyRefs.map((element, index) => (
                      <MotifOption value={element.ReferenceValue} key={index}>
                        {element.ReferenceKey}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                )}
              </MotifFormField>
            </div>
            <div className="fldCurrency">
              <MotifFormField>
                <MotifLabel className="fldLabel">Currency</MotifLabel>
                <MotifInput
                  onChange={(e) => onCurrencyChange(e.target.value)}
                  type="text"
                  value={currency}
                  className={isErrorOnCurrency ? "motif-input-invalid" : ""}
                  hideClearButton={!!isErrorOnCurrency}
                />
                {isErrorOnCurrency && (
                  <>
                    <img
                      src={iconError}
                      alt="Error"
                      className="iconError topPosCurrency"
                    />
                    <MotifErrorMessage className="errorText">
                      <div>{errMsgAlphaNumWithMax50Chars}</div>
                    </MotifErrorMessage>
                  </>
                )}
                {!isErrorOnCurrency && (
                  <div className="tableDetails">
                    Please enter alphanumeric name, up to 50 characters.
                  </div>
                )}
              </MotifFormField>
            </div>
            <div className="fldUnit">
              <MotifFormField>
                <MotifLabel className="fldLabel">Unit</MotifLabel>
                <MotifInput
                  onChange={(e) => onUnitChange(e.target.value)}
                  type="text"
                  value={unit}
                  className={isErrorOnUnit ? "motif-input-invalid" : ""}
                  hideClearButton={!!isErrorOnUnit}
                />
                {isErrorOnUnit && (
                  <>
                    <img
                      src={iconError}
                      alt="Error"
                      className="iconError topPosUnit"
                    />
                    <MotifErrorMessage className="errorText">
                      <div>{errMsgAlphaNumWithMax50Chars}</div>
                    </MotifErrorMessage>
                  </>
                )}
                {!isErrorOnUnit && (
                  <div className="tableDetails">
                    Please enter alphanumeric name, up to 50 characters.
                  </div>
                )}
              </MotifFormField>
            </div>
          </>
        )}
      </MotifModalBody>
      <MotifModalFooter className="addTableFooter">
        <MotifButton
          onClick={() => setOpenModal(!openModal)}
          type="button"
          variant="secondary"
          className="btnCancel"
        >
          Cancel
        </MotifButton>
        {currentStep === 1 && (
          <MotifButton
            onClick={() => gotoStep(2)}
            type="button"
            className="btnNext"
            disabled={
              !selectedRange || isErrorOnRange || isErrorOnDuplicateRange
            }
            data-testid="testIdBtnNext"
          >
            Next
          </MotifButton>
        )}
        {currentStep === 2 && (
          <MotifButton
            onClick={() => {
              isAddClicked === true ? addTable() : updateTable();
            }}
            type="button"
            className="btnNext"
            disabled={
              disableAddTableButton ||
              !selectedRange ||
              !tableName ||
              isErrorOnTableName ||
              !tableType ||
              !frequency ||
              !currency ||
              isErrorOnCurrency ||
              !unit ||
              isErrorOnUnit
            }
          >
            {isAddClicked === true ? "Add" : "Update"}
          </MotifButton>
        )}
        {
          <div>
            {show && (
              <EditRangeWarning
                setOpenModal={showPopUp}
                setconfirm={confirmMenuClick}
              ></EditRangeWarning>
            )}
          </div>
        }
      </MotifModalFooter>
    </MotifModal>
  );
};

AddTable.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  tables: PropTypes.array,
  closeModal: PropTypes.func,
  isAddClicked: PropTypes.bool,
  selectedTable: PropTypes.object,
};

export default AddTable;
