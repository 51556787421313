/**
 * Delete a  relationship 
 * Created On: 21-08-2024
 */

import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React from "react";
import warningCircle from '../../../../assets/images/warning.svg';
import "./DeleteRelationshipConfirmModal.scss"


const DeleteRelationshipConfirmModal = ({ onClose, handleDelete }) => {

    return (
        <div>
            <MotifModal id="deleteWarningModal" show={true} size="sm"

                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: 'none',
                    },
                }}
                onClose={onClose}
                className='deleteWarningModal confirmModal modalMotifFooterModal'
            >
                <MotifModalHeader
                    className='deleteWarningHeader'
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                >
                    <div className='closeSymbolContainer'>
                        <img className='warningCircle' alt='icon' src={warningCircle} />
                    </div>
                    <div className='deleteText'>Remove Relationships</div>
                </MotifModalHeader>
                <MotifModalBody className='deleteWarningBody'>
                    <React.Fragment key=".0">

                        <div className='deleteWarningLines'>
                            <span >
                                These relationships may have been used in Line Analysis regenerate latest results.This action cannot be undone. Click 'Confirm' to continue.
                            </span>
                        </div>
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter className='deleteWarningFooter'>
                    <React.Fragment key=".0">
                        <MotifButton
                            className='btnCancel '
                            label="btnCancel"
                            size="small"
                            type="button"
                            variant="secondary"
                            onClick={onClose}
                        >
                            Cancel
                        </MotifButton>
                        <MotifButton
                            label="btnConfirm"
                            size="small"
                            type="button"
                            variant="primary"
                            className='btnConfirm'
                            onClick={handleDelete}
                        >
                            Confirm
                        </MotifButton>
                    </React.Fragment>
                </MotifModalFooter>
            </MotifModal>
        </div>
    );
};

export default DeleteRelationshipConfirmModal;