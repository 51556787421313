/**
 * Table Details page of DaaS application
 * opens when table is selected from Project Details page
 * Created Date: 19/07/2024
 */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';

/**
 * Motif controls
 */
import MotifButton, { MotifIconButton } from "@ey-xd/motif-react/Button";
import MotifTabNavigation, {
    MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import { MotifTooltip, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifInlineMessage, MotifFormField, MotifSearch } from "@ey-xd/motif-react";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";
import MotifDropdown, { MotifDropdownItem } from '@ey-xd/motif-react/Dropdown';
import MotifToast from '@ey-xd/motif-react/Toast';

/**
 * Internal imports
 */
import './TableDetails.scss';
import TableService from '../../../services/tableService';
import Utils from '../../../utils/Utils';
import Commentary from "../commentary/Commentry";
import ExportQnA from "../../molecules/modals/exportQnA/ExportQnA";
import ClearLineAnalysis from "../../molecules/modals/clearLineAnalysis/ClearLineAnalysis";

//  SVG Icons imports
import iconArrowBack from '../../../assets/images/arrow_back.svg';
import iconDocument from '../../../assets/images/page.svg';
import iconRelationship from '../../../assets/images/network-alt.svg';
import iconThreshold from '../../../assets/images/ease-curve-control-points.svg';
import iconGenAI from '../../../assets/images/sparks.svg';
import iconThumbsUp from '../../../assets/images/thumbs-up.svg';
import iconThumbsUpFilled from '../../../assets/images/thumbs-up-filled.svg';
import iconThumbsDown from '../../../assets/images/thumbs-down.svg';
import iconThumbsDownFilled from '../../../assets/images/thumbs-down-filled.svg';
import iconMore from '../../../assets/images/more-horiz.svg';
import iconCopy from '../../../assets/images/copy.svg';
import iconArrowUp from '../../../assets/images/nav-arrow-up.svg';
import iconArrowDown from '../../../assets/images/nav-arrow-down.svg';
import iconCheckCircle from "../../../assets/images/check-circle.svg";
import iconProgressLoader from "../../../assets/images/progress-loader.svg";
import iconInfo from "../../../assets/images/info-empty.svg";
import iconProgressLoaderCircle from "../../../assets/images/Loading_indicator_circles.svg";
import iconAdditionalContext from '../../../assets/images/stats-up-square.svg';
import warning_error from "../../../assets/images/warning_error.svg";
import iconError from '../../../assets/images/error.svg';
import iconWhiteCheckCircle from "../../../assets/images/white-check-circle.svg";
import iconWarning from "../../../assets/images/warning-triangle.svg";
import iconUser from '../../../assets/images/user.svg';
import expandButton from '../../../assets/images/expand-button.svg'
import iconWarningCircle from '../../../assets/images/warning-circle.svg';
import featured_icon from '../../../assets/images/featured_icon.svg';
import iconWarningInfoCircle from '../../../assets/images/warning_info_circle.svg';
import brokenLink from '../../../assets/images/broken-link.svg';
import IconDataTransfer from '../../../assets/images/IconDataTransfer.svg';
import iconQA from '../../../assets/images/iconQA.svg';

import TableRelationship from '../../molecules/modals/tableRelationship/TableRelationship';
import { setTableAnalysis } from "../../../auth/analysisSlice";
import { setShowToast } from "../../../auth/toastSlice";
import TableThreshold from "../../molecules/modals/tableThreshold/TableThreshold";
import DisplayQAModal from '../../molecules/modals/displayQAModal/DisplayQAModal';
import UploadQA from '../../molecules/modals/uploadQA/UploadQA';
import AdditionalContext from "../../molecules/modals/additionalContext/AdditionalContext";
import Documents from '../../molecules/modals/documents/Documents';
import AnalysisSettings from '../../molecules/modals/analysisSettings/AnalysisSettings';
import AnalysisService from "../../../services/analysisService";

import relationship_red from '../../../assets/images/relationship_red.svg';
import additinal_context_red from '../../../assets/images/additinal_context_red.svg';
import document_red from '../../../assets/images/document_red.svg';
import line_circle_red from '../../../assets/images/line_circle_red.svg';
import searchIcon from '../../../assets/images/search.svg';
import iconLineAnalysis from '../../../assets/images/iconLineAnalysis.svg';
import iconTableAnalysis from '../../../assets/images/iconTableAnalysis.svg';
import iconLineAnalysisRed from '../../../assets/images/iconLineAnalysisRed.svg';
import iconTableAnalysisRed from '../../../assets/images/iconTableAnalysisRed.svg';

const TableDetails = ({
    tableLAStat,
    onCloseSelectedTable,
    tables
}) => {

    //  Custom constants
    //  Tab (Table details & Line Analysis) details
    const vTabs = {
        tableDetails: [
            {
                id: 1,
                elementId: "idTabContentLineAnalysis",
                title: "Line Analysis",
            },
            {
                id: 2,
                elementId: "idTabContentTableAnalysis",
                title: "Table Analysis",
            },
        ],
        lineAnalysis: [
            {
                id: 1,
                elementId: "idTabContentDescription",
                title: "Description",
            },
            {
                id: 2,
                elementId: "idTabContentQA",
                title: "Q&A",
            },
            {
                id: 3,
                elementId: "idTabContentCommentary",
                title: "Commentary",
            },
        ],
    };
    const vTabIds = {
        tableDetails: {
            lineAnalysis: "idTabContentLineAnalysis",
            tableAnalysis: "idTabContentTableAnalysis",
        },
        lineAnalysis: {
            description: "idTabContentDescription",
            qa: "idTabContentQA",
            commentary: "idTabContentCommentary",
        },
    };
    const vExcelFileFormats = ["xls", "xlsx", "xlsm", "xlsb"];

    //  State variables
    const { user, activeProject } = useSelector(state => state.user);
    const { selectedTable } = useSelector(state => state.table);
    const [selectedTableData, setSelectedTableData] = useState(null);
    const [displayTableData, setDisplayTableData] = useState(null);
    const [selectedTableDetailsTab, setSelectedTableDetailsTab] = useState(
        vTabIds.tableDetails.lineAnalysis
    );
    const [selectedLineAnalysisTab, setSelectedLineAnalysisTab] = useState(
        vTabIds.lineAnalysis.description
    );
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [reapplySelections, setReapplySelections] = useState(false);
    const [lineAnalysisData, setLineAnalysisData] = useState();
    const [tableAnalysisData, setTableAnalysisData] = useState([]);
    const [showLineCommentaryModal, setShowLineCommentaryModal] = useState(false);
    const [checkRowIds, setCheckRowIds] = useState([]);
    const [isProceedBtnDisabled, setIsProceedBtnDisabled] = useState(false);
    const [rowStatusData, setRowStatusData] = useState([]);
    const [lineAnalysisStatus, setLineAnalysisStatus] = useState("");
    const [usedDocumentList, setUsedDocumentList] = useState([]);
    const [deletedDocumentList, setDeletedDocumentList] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState();
    const [notificationMessage, setNotificationMessage] = useState();
    const [isCallForTableAnalysis, setIsCallForTableAnalysis] = useState(false);
    const [isTableCommentaryInitiated, setIsTableCommentaryInitiated] = useState(false);
    const [isTableCommentaryGenerated, setIsTableCommentaryGenerated] = useState(false);
    const [showExportQnAModal, setShowExportQnAModal] = useState(false);
    const [showTableThresholdModal, setShowTableThresholdModal] = useState(false);
    const [exportImportDetails, setExportImportDetails] = useState(null);
    const [isAllLineAnalysisNotGenerated, setIsAllLineAnalysisNotGenerated] = useState(false);
    const [isAllLineAnalysisInProgress, setIsAllLineAnalysisInProgress] = useState(false);
    const [isTableAnalysisGenerated, setIsTableAnalysisGenerated] = useState(false);
    const [displayAdditionalContextModal, setDisplayAdditionalContextModal] = useState(false);
    const [isRelationshipEnabled, setIsRelationshipEnabled] = useState(false);
    const [displayUploadQAModal, setDisplayUploadQAModal] = useState(false);
    const [displayDocumentsModal, setDisplayDocumentsModal] = useState(false);
    const [vwSrcDocsDropdownId, setVwSrcDocsDropdownId] = useState(null);
    const [isLineCommentaryInitiated, setIsLineCommentaryInitiated] = useState(false);
    const [showDocumentIcon, setShowDocumentIcon] = useState(false);
    const [showRelationshipIcon, setShowRelationshipIcon] = useState(false);
    const [showKpiIndIcon, setShowKpiIndIcon] = useState(false);
    const [showLineAnalysisIcon, setShowLineAnalysisIcon] = useState(false);
    const [showTableAnalysisIcon, setShowTableAnalysisIcon] = useState(false);
    const [showUploadQnALoader, setShowUploadQnALoader] = useState(false);
    const [fromUploadProcess, setFromUploadProcess] = useState(false);
    const [isSomeLineAnalysisGenerated, setIsSomeLineAnalysisGenerated] = useState(false);
    const [isRegenTableAnalysis, setIsRegenTableAnalysis] = useState(false);
    const [lastOperationStatus, setLastOperationStatus] = useState("");
    const [showLAPopover, setShowLAPopover] = useState(false);
    const [currentVLineItem, setCurrentVLineItem] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [showTransferToastTableAnalysis, setShowTransferToastTableAnalysis] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [displayQAModal, setDisplayQAModal] = useState(false)
    const { showToast, toastDismissed } = useSelector((state) => state.toast)
    const [idVwSrcDocsQA, setIdVwSrcDocsQA] = useState(null);
    const [indexVwSrcDocsQA, setIndexVwSrcDocsQA] = useState(null);
    const [toggleTypeVwSrcDocsQA, setToggleTypeVwSrcDocsQA] = useState(null);
    const [isOpenVwSrcDocsQA, setIsOpenVwSrcDocsQA] = useState(false);
    const [displayUpArrowIcon, setDisplayUpArrowIcon] = useState(false);
    const [lineAnalysisWarning, setLineAnalysisWarning]=useState(false);
    const MAX_SELECTION=process.env.REACT_APP_MAX_SELECTION ;
    const [showNotificationOnModal, setShowNotificationOnModal] = useState(false);
    const [notificationTypeOnModal, setNotificationTypeOnModal] = useState();
    const [notificationMessageOnModal, setNotificationMessageOnModal] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const tableService = new TableService();
    const analysisService = new AnalysisService();
    const utils = new Utils();
    const dispatch = useDispatch();
    const commentaryTabId = vTabs.lineAnalysis.find(tab => tab.title === "Commentary")?.id;
    const [showClearModal, setShowClearModal] = useState(false);
    const [isClearBtnDisabled, setIsClearBtnDisabled] = useState(false);
    const [checkedRowHeader, setCheckedRowHeader] = useState([]);
    const [checkedDataToClear, setCheckedDataToClear] = useState([]);

    //  Navigate to project details
    const navToProjectDetails = () => {
        dispatch(setTableAnalysis({
            tableAnalysis: null
        }));
        onCloseSelectedTable(true);
    };

    //  Open threshold popover.
    const openThresholdPopover = () => {
        setShowTableThresholdModal(true);
    };
    const [displayTableRelationshipModal, setDisplayTableRelationshipModal] = useState(false);
    const [isLineAnalysis, setIsLineAnalysis] = useState(true);
    const [isTableAnalysis, setIsTableAnalysis] = useState(false);

    /**
     * Useeffect hook to populate selected table data from parent page
     */
    useEffect(() => {
        if (selectedTable) {
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTable));
            for (const element of vSelectedTableData.tableContent) {
                element['open'] = false;
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setDisplayTableData(vSelectedTableData);
            getExportImportDetails(selectedTable);
        }
        if (tables.length >= 2) {
            setIsRelationshipEnabled(true);
        }
        else {
            setIsRelationshipEnabled(false);
        }
        // eslint-disable-next-line
    }, [selectedTable]);

    useEffect(() => {
        if (
            notificationMessage === "Q&A exported successfully" ||
            notificationMessage === "Export failure"
        ) {
            getExportImportDetails(selectedTable);
        }
        // eslint-disable-next-line
    }, [notificationMessage]);

    useEffect(() => {
        if (showUploadQnALoader) {
            setIsLineCommentaryInitiated(true);
        }
        else {
            if (notificationMessage !== '') {
                setIsLineCommentaryInitiated(false);
                getExportImportDetails(selectedTable);
            }
        }
        // eslint-disable-next-line
    }, [showUploadQnALoader]);

    useEffect(() => {
        let vSelectedTable = JSON.parse(JSON.stringify(displayTableData));
        if (vSelectedTable != null) {
            if (isSelectAll) {
                let checkboxes = document.querySelectorAll('input[type="checkbox"]');
                let rowIds = [];

                for (const element of vSelectedTable.tableContent) {
                    for (const checkbox of checkboxes) {
                        const ChckID = checkbox.id.replace('lineItemCheckbox', '');
                        if (ChckID === element.rowId) {
                            if (checkbox.disabled) {
                                element.selected = false;
                            }
                            else {
                                element.selected = true;
                                rowIds.push(element.rowId);
                            }
                        }
                    }

                }
                if (rowIds.length > 0) {
                    setCheckRowIds(rowIds);
                }
            }
            setDisplayTableData(vSelectedTable);
        }
        //  eslint-disable-next-line
    }, [reapplySelections])

    useEffect(() => {
        if (toastDismissed) {
            setIsButtonDisabled(false);
        }
    }, [toastDismissed])



    /**
     * Function to switch tabs
     * @param {*} tab
     */
    const handleTabClick = (vTabType, tab, vRowId) => {
        setActiveTab(tab.id)
        if (vTabType === "Table Details") {
            setSelectedTableDetailsTab(tab.elementId);
            switch (tab.elementId) {
                case vTabIds.tableDetails.lineAnalysis:
                    document.getElementById(vTabIds.tableDetails.lineAnalysis).style.display = "block";
                    document.getElementById(vTabIds.tableDetails.tableAnalysis).style.display = "none";
                    setIsCallForTableAnalysis(false);
                    break;
                case vTabIds.tableDetails.tableAnalysis:
                    document.getElementById(vTabIds.tableDetails.lineAnalysis).style.display = "none";
                    document.getElementById(vTabIds.tableDetails.tableAnalysis).style.display = "block";
                    setIsCallForTableAnalysis(true);
                    if (isProceedBtnDisabled) {
                        setIsProceedBtnDisabled(false);
                    }
                    if (isClearBtnDisabled) {
                        setIsClearBtnDisabled(false);
                    }
                    break;
                default:
            }
        } else {
            setSelectedLineAnalysisTab(tab.elementId);
            switch (tab.elementId) {
                case vTabIds.lineAnalysis.description:
                    document.getElementById(
                        vTabIds.lineAnalysis.description + "~" + vRowId
                    ).style.display = "block";
                    document.getElementById(
                        vTabIds.lineAnalysis.qa + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.commentary + "~" + vRowId
                    ).style.display = "none";
                    break;
                case vTabIds.lineAnalysis.qa:
                    document.getElementById(
                        vTabIds.lineAnalysis.description + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.qa + "~" + vRowId
                    ).style.display = "block";
                    document.getElementById(
                        vTabIds.lineAnalysis.commentary + "~" + vRowId
                    ).style.display = "none";
                    break;
                case vTabIds.lineAnalysis.commentary:
                    document.getElementById(
                        vTabIds.lineAnalysis.description + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.qa + "~" + vRowId
                    ).style.display = "none";
                    document.getElementById(
                        vTabIds.lineAnalysis.commentary + "~" + vRowId
                    ).style.display = "block";
                    break;
                default:
            }
        }
    };
    /**
     * Function for  Transfer commentary to PowerPoint addin
     * 
     */

    const handleTransferToPPTClick = async (isLineAnalysis) => {
        setIsButtonDisabled(true);
        const vData = {
            projectId: projectId,
            resultId: isLineAnalysis ? lineAnalysisData.results[2].resultId : tableAnalysisData[0].resultId,
            sourceSystem: isLineAnalysis ? "DaaS_LineCommentary" : "DaaS_TableAnalysis",
            answer: isLineAnalysis ? lineAnalysisData.results[2].answer : tableAnalysisData[0].answer,
            userId: userId
        }
        try {
            const response = await analysisService.transferToPPT(
                vData.projectId,
                vData.resultId,
                vData.sourceSystem,
                vData.answer,
                vData.userId
            );
            if (response) {
                if (isLineAnalysis) {
                    displayNotificationOnModal('success','Transfer successful');
                } else {
                    setShowTransferToastTableAnalysis(true)
                }

            } else {
                dispatch(setShowToast({ showToast: true, toastVariant: "error" }));
            }
        } catch (error) {
            dispatch(setShowToast({ showToast: true, toastVariant: "error" }));
        } finally {
            setIsButtonDisabled(false)
        }
    };
    //  Proceed button functionality to initiate line analysis from backend
    const proceed = () => {
        setShowLineCommentaryModal(true);
    };

    const showExportQnAClick=()=>{
        setShowExportQnAModal(true);
        setDisplayQAModal(false);
    }


    const showUploadQnAClick=()=>{
        setDisplayUploadQAModal(true);
        setDisplayQAModal(false);
    }

    /*
     * Function to get Enabled checkboxes count
     * @returns 
     */
    const getEnabledCheckboxesCount = () => {
        let vEnabledCheckboxesCount = 0;
        let vSelectedTable = JSON.parse(JSON.stringify(selectedTableData));
        let checkboxes = document.querySelectorAll('input[type="checkbox"]');
        for (const element of vSelectedTable.tableContent) {
            for (const checkbox of checkboxes) {
                const ChckID = checkbox.id.replace('lineItemCheckbox', '');
                if ((ChckID === element.rowId) && !checkbox.disabled) {
                    vEnabledCheckboxesCount += 1;
                }
            }
        }
        return vEnabledCheckboxesCount;
    };

    /**
     * Function to toggle more dropdown menu
     * @param {*} vDropdownMenuId 
     * @param {*} vToggle 
     */
    const toggleDropdownMenuMore = (vDropdownMenuId, vToggle) => {
        const vDropdown = document.getElementById('more-dropdown-' + vDropdownMenuId);
        if (vDropdown && (vToggle === 'Open')) {
            vDropdown.style.display = 'block';
        } else if (vDropdown && (vToggle === 'Close')) {
            vDropdown.style.display = 'none';
        }
    };


    /**
     * Function for Copy line commentary
     * @param {*} vLineItemId 
     */
    const onCopy = (vLineItemId) => {
        let r = document.createRange();
        r.selectNode(document.getElementsByClassName('lineCommentary-' + vLineItemId)[0]);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        displayNotificationOnModal('success','Copied');
        toggleDropdownMenuMore(vLineItemId, 'Close');
    };

    /**
     * Function to get line analysis details for opened line item
     * @param {*} vLineItemId 
     */
    const getLineAnalysisDetails = async (vLineItemId) => {
        const userId = user.username;
        const vData = {
            projectId: projectId,
            tableId: selectedTableData.id,
            rowId: vLineItemId,
            userId: userId,
        };
        const vLineAnalysisData = await tableService.getLineAnalysisDetails(
            vData
        );
        setLineAnalysisData(vLineAnalysisData);
    };

    /**
     * Function to extract line analysis data
     * @param {*} vType 
     * @returns 
     */
    const extractLineAnalysisData = (vType, vField) => {
        let vLineAnalysisData;
        const vLineAnalysis = lineAnalysisData?.results;
        if (vLineAnalysis.length > 0) {
            const tmpLineAnalysisData = vLineAnalysis.filter(element => (element.queueMethod === vType))[0];
            if (tmpLineAnalysisData && tmpLineAnalysisData[vField]) {
                vLineAnalysisData = tmpLineAnalysisData[vField];
            }
        }

        return vLineAnalysisData;
    };

    /**
     * Function to Submit feedback as Like/Dislike
     * @param {*} vFeedbackType 
     * @param {*} vLineItem 
     */
    const onSubmitFeedabck = async (vFeedbackType, vLineItem, vFeedback) => {
        let userFeedback;
        if (vFeedbackType === 'Like') {
            if (vLineItem.feedback === 1) {
                userFeedback = 0;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = 1;
            } else if (vFeedback === 1) {
                userFeedback = 0;
            } else if (vFeedback === 0) {
                userFeedback = 1;
            } else {
                userFeedback = 1;
            }
        } else {
            if (vLineItem.feedback === -1) {
                userFeedback = 0;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = -1;
            } else if (vFeedback === -1) {
                userFeedback = 0;
            } else if (vFeedback === 0) {
                userFeedback = -1;
            } else {
                userFeedback = -1;
            }
        }
        const vData = {
            "projectId": projectId,
            "tableId": selectedTableData.id,
            "rowId": vLineItem.rowId,
            "queueMethod": "lineCommentary",
            "status": "finished",
            "feedback": userFeedback
        };
        const response = await tableService.submitFeedback(vData);
        if (response) {
            vLineItem.feedback = response.feedback;
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
            for (const element of vSelectedTableData.tableContent) {
                if (element.rowId === vLineItem.rowId) {
                    element.feedback = response.feedback;
                }
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setDisplayTableData(vSelectedTableData);
            displayNotificationOnModal('success', 'Successfully sent feedback.');
        } else {
            displayNotificationOnModal('error', 'Failed to send feedback.');
        }
    };

    const getFeedback = (vFeedbackType, vLineItem, vFeedback) => {
        let userFeedback;
        if (vFeedbackType === 'Like') {
            if (vLineItem.feedback === 1) {
                userFeedback = 1;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = 0;
            } else if (vFeedback === 1) {
                userFeedback = 1;
            } else if (vFeedback === 0) {
                userFeedback = 0;
            } else {
                userFeedback = 0;
            }
        } else {
            if (vLineItem.feedback === -1) {
                userFeedback = -1;
            } else if (vLineItem.vFeedback === 0) {
                userFeedback = 0;
            } else if (vFeedback === -1) {
                userFeedback = -1;
            } else if (vFeedback === 0) {
                userFeedback = 0;
            } else {
                userFeedback = 0;
            }
        }
        return userFeedback;
    };

    const handleCloseModal = () => {
        setShowLineCommentaryModal(false);
        setFromUploadProcess(false);
    };

    const handleClearBtnClick = () => {
        setShowClearModal(true);
        const completedRowIds = checkRowIds?.filter(rowId => 
            rowStatusData.find(row => row.rowId === rowId)?.status === "Completed"
        );
        if(completedRowIds?.length > 0){
            const newClearData = completedRowIds.map(rowId => ({
                tableId,
                rowId
            }));
            setCheckedDataToClear(prevData => [...prevData, ...newClearData]);
        }
    }

    const handleClearModalClose = () => {
        setShowClearModal(false);
        clearAllLineItemSelection();
        setCheckRowIds([]);
        setCheckedRowHeader([]);
        setIsProceedBtnDisabled(true);
        setIsClearBtnDisabled(true);
        setCheckedDataToClear([]);
    }

    const handleClearAnalysis = async() => {
        const cLData = {
            ids: checkedDataToClear,
            userId: user.username
        };
        const response = await tableService.clearLineAnalysis(cLData);
        if (response) {
            setNotificationMessage("Line analysis cleared successfully");
            setShowNotification(true);
            setNotificationType("info");
            clearAllLineItemSelection();
            setCheckRowIds([]);
            setCheckedRowHeader([]);
            setIsProceedBtnDisabled(true);
            setIsClearBtnDisabled(true);
            setCheckedDataToClear([]);
            getTableAnalysisData();
        }
        setShowClearModal(false);
    };

    /**
    * Function to open Documents Modal
    */
    const showDocuments = () => {
        setDisplayDocumentsModal(true);
    }

    const projectId = activeProject.project_UUID;
    const userId = user.username;
    const tableId = selectedTable.id;

    /**
     * This async function used to initiate Line Analysis process once click on Confirm button
     * @param {Array} data :  List of selected documents and Line commentary Excel check value
     */
    const handleConfirmClick = async (data) => {
        if (isCallForTableAnalysis) {
            setIsTableCommentaryInitiated(true);
            setShowLineCommentaryModal(false);
            setIsProceedBtnDisabled(true);
            setIsClearBtnDisabled(true);
            setIsTableCommentaryGenerated(false);
            const vData = {
                tableId: tableId,
                projectId: projectId,
                userId: userId,
                isCommentaryExcel: data.isCommentaryExcel
            }
            const tableAnalysisStatus = await analysisService.initialiseTableAnalysis(vData);
            if (tableAnalysisStatus === 200) {
                //  Do nothing, handover to polling
            }
        } else {
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
            for (const element of vSelectedTableData.tableContent) {
                element.open = false;
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setCheckRowIds([]);
            setCheckedRowHeader([]);
            setIsLineCommentaryInitiated(true);
            setShowLineCommentaryModal(false);
            setIsProceedBtnDisabled(true);
            setIsClearBtnDisabled(true);
            const vData = {
                tableId: tableId,
                projectId: projectId,
                checkRowIds: checkRowIds,
                documents: data.documents,
                qnaFlag: data.qnaFlag,
                fulltableFlag: data.fulltableFlag,
                promptIds: data.promptIds,
                userId: userId,
                isCommentaryExcel: data.isCommentaryExcel
            };
            const lineAnalysisStatus = await analysisService.initLineAnalysis(vData)
            if (lineAnalysisStatus === 200) {
                setIsLineCommentaryInitiated(false);
                setLineAnalysisWarning(true)
                getRowStatus()
                checkAllRowStatusCompleted(rowStatusData);
            }
            clearAllLineItemSelection();
        }
    };

    const handleAddtoExcelClick = () => {
        generateLineCommentaryInExcel();
    }

    const handleTableAddtoExcelClick = () => {
        generateTableCommentaryInExcel();
    }

    /**
     * Get the Line Analysis status data
     */
    const getRowStatus = async () => {
        const data = await analysisService.getRowStatus(tableId, projectId, userId);
        setIsLoading(false);
        if (data && Object.keys(data).length > 0) {
            setRowStatusData(data.rowIds);
            setUsedDocumentList(data.documentList);
            setDeletedDocumentList(data.deleted_document_list);
            setLastOperationStatus(data.lastOperation);
            const indicators = data.indicators || {};
            const {
                // eslint-disable-next-line
                cnt,
                documentInd,
                relationshipInd,
                kpiInd,
                lineanalysisInd,
                tableanlysisInd
            } = indicators;
            setShowDocumentIcon(documentInd === 0);
            setShowRelationshipIcon(relationshipInd === 0);
            setShowKpiIndIcon(kpiInd === 0);
            setShowLineAnalysisIcon(lineanalysisInd === 0);
            setShowTableAnalysisIcon(tableanlysisInd === 0);
            setLineAnalysisStatus(checkStatus(data.rowIds));
            setIsAllLineAnalysisNotGenerated(data.rowIds.every(row => row.status === 'No Analysis Initiated'));
            setIsSomeLineAnalysisGenerated(data.rowIds.some(row => row.status === 'Completed'));
            setIsTableAnalysisGenerated(data.tableAnalysisStatus);
            setIsAllLineAnalysisInProgress(data.rowIds.every(row => row.status === 'In-Progress'));
            clearCommentaryInExcel(data);
        }
    };

    /**
     * Use Effect hook to check if we received newly generated table analysis
     */
    useEffect(() => {
        if ((isTableAnalysisGenerated === 'In-Progress') && isTableCommentaryInitiated) {
            setIsRegenTableAnalysis(true);
        }
        if ((isTableAnalysisGenerated === 'Completed') && isTableCommentaryInitiated && isRegenTableAnalysis) {
            getTableAnalysisData();
            setIsProceedBtnDisabled(false);
            setIsClearBtnDisabled(false);
        }
        //  eslint-disable-next-line
    }, [isTableAnalysisGenerated])

    /**
     * Checks the status of a list of data entries and returns the overall status.
     * The function evaluates the statuses of each entry in the provided data array
     * and returns the first matching status based on the following priority:
     * 1. 'In-Progress'
     * 2. 'No Analysis Initiated'
     * 3. 'Completed'
     * 
     * @param {Array} data - An array of objects, each containing a 'status' property.
     * @returns {string|undefined} - Returns the first matching status as a string or undefined if no statuses match.
     */
    const checkStatus = (data) => {
        if (data.some(row => row.status === 'In-Progress')) {
            return 'In-Progress';
        } else if (
            data.some(row => row.status === 'No Analysis Initiated') ||
            data.some(row => row.status === 'Failure')
        ) {
            return 'No Analysis Initiated';
        } else if (data.some(row => row.status === 'Completed')) {
            return 'Completed';
        }
    };

    /**
     * Checks the overall status of a list of data entries and updates the line analysis status.
     * This function uses the `checkStatus` function to determine the current status of the data.
     * If the status is 'In-Progress', it calls the `getRowStatus` function to refresh the row statuses.
     * Regardless of the status, it then calls `getRowStatus` again to ensure the most up-to-date information is retrieved.
     * Finally, it sets the line analysis status using the determined row status.
     *
     * @param {Array} data - An array of objects, each containing a 'status' property.
     */
    const checkAllRowStatusCompleted = (data) => {
        const rowStatus = checkStatus(data)
        if (rowStatus === 'In-Progress') {
            getRowStatus();
        }
        getRowStatus();
    };

    useEffect(() => {
        getTableAnalysisData();
        setIsLoading(true);
        getRowStatus();
        const interval = setInterval(() => {
            checkAllRowStatusCompleted(rowStatusData);
        }, 10000);
        return () => clearInterval(interval); // Cleanup function to clear interval
        // eslint-disable-next-line
    }, []);

    /**
     * Function to check whether to dsplay Description/Q&A/Commentary tabs with default message or content
     * @param {*} vLineAnalysisType 
     * @param {*} vLineItem 
     * @returns 
     */
    const displayLineAnalysis = (vLineAnalysisType, vLineItem) => {
        let displayLineAnalysis = false;
        if (
            vLineItem &&
            lineAnalysisData &&
            (vLineItem.rowId === lineAnalysisData.rowId) &&
            lineAnalysisData.results &&
            (lineAnalysisData.results.length > 0)
        ) {
            const tmpLineAnalysis = lineAnalysisData.results.filter(element => (element.queueMethod === vLineAnalysisType))[0];
            if ((vLineAnalysisType === 'lineDescription') || (vLineAnalysisType === 'lineCommentary')) {
                if (tmpLineAnalysis && tmpLineAnalysis.answer && (tmpLineAnalysis.answer !== '')) {
                    displayLineAnalysis = true;


                }
            } else if (vLineAnalysisType === 'lineQuestions') {
                if (tmpLineAnalysis && tmpLineAnalysis.extractions && (tmpLineAnalysis.extractions.length > 0)) {
                    displayLineAnalysis = true;
                }
            }
        }
        return displayLineAnalysis;
    };

    /**
     * Function to display notification on popover modal
     * @param {*} vType 
     * @param {*} vMessage 
     */
    const displayNotificationOnModal = (vType, vMessage) => {
        setShowNotificationOnModal(true);
        setNotificationTypeOnModal(vType);
        setNotificationMessageOnModal(vMessage);
    };

    /**
     * 
     * @param {*} selectedTable 
     * @returns Export import details.
     */
    const getExportImportDetails = async (selectedTable) => {
        const data = {
            "tableId": selectedTable.id,
            "projectId": projectId,
            "userId": user.username
        };
        const response = await tableService.getExportImportDetails(data);
        setExportImportDetails(response);
    };

    /*
     * Function will be called when add table relationship modal closes
     * @param {*} vStatus 
     * @param {*} vError 
     */
    const closeTableRelationshipModal = () => {
        setDisplayTableRelationshipModal(false);
    };

    /**
     * Function to set close KPIs/Metrics modal
     */
    const closeAdditionalContextModal = () => {
        setDisplayAdditionalContextModal(false);
    };

    /**
     * Function to open add table relationship popup
     */
    const setTableRelationship = () => {
        setDisplayTableRelationshipModal(true);
    } 

    /**
     * Function to open the QA popup
     */
    const showQA = () => {
        setDisplayQAModal(true);
    }
    /**
     * Function to get table analysis data
     */

    const getTableAnalysisData = async (data) => {
        const tableAnalysisData = await analysisService.getTableAnalysisData(tableId)
        if (tableAnalysisData && (tableAnalysisData.length > 0)) {
            setTableAnalysisData(tableAnalysisData);
            const isAnswerExist = tableAnalysisData.some(item => item.hasOwnProperty('answer'));
            if (isAnswerExist === true) {
                setIsTableCommentaryGenerated(true);
                dispatch(setTableAnalysis({
                    tableAnalysis: tableAnalysisData[0]
                }));
            } else {
                setIsTableCommentaryGenerated(false);
                setIsTableCommentaryInitiated(false);
            }
        }
    }

    /**
     * Check if Proceed button disabled due to
     * 1. All line item analyis status is not generated or
     * 2. Some of the line item analysis is in progress
     * @returns true or false
     */
    const getTableAnalysisProceedDisableState = () => {
        let vTableAnalysisProceedDisableState = false;
        if (
            (selectedTableDetailsTab === 'idTabContentTableAnalysis') &&
            (
                isAllLineAnalysisNotGenerated ||
                !isSomeLineAnalysisGenerated ||
                (lineAnalysisStatus === 'In-Progress') ||
                (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                (isTableAnalysisGenerated === "In-Progress") ||
                (lastOperationStatus !== "Line Analysis")
            )
        ) {
            vTableAnalysisProceedDisableState = true;
        }
        return vTableAnalysisProceedDisableState;
    };

    /**
     * check if last operation is table analysis
     * @returns true or false
     */
    const isTableAnalysisGenerationLastOperation = () => {
        let vIsTableAnalysisGenerationLastOperation = false;
        if (
            (selectedTableDetailsTab === 'idTabContentTableAnalysis') &&
            (
                (lineAnalysisStatus !== 'In-Progress') &&
                (lastOperationStatus === "Table Analysis")
            )
        ) {
            vIsTableAnalysisGenerationLastOperation = true;
        }
        return vIsTableAnalysisGenerationLastOperation;
    };

    /**
     * Check if Proceed button disabled due to
     * 1. All line item analysis status is in progress or
     * 2. Table analysis status is in progress
     * @returns true or false
     */
    const getLineAnalysisProceedDisableState = () => {
        let vLineAnalysisProceedDisableState = false;
        if (
            (selectedTableDetailsTab === 'idTabContentLineAnalysis') &&
            (
                isAllLineAnalysisInProgress ||
                (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                (isTableAnalysisGenerated === "In-Progress")
            )
        ) {
            vLineAnalysisProceedDisableState = true;
        }
        return vLineAnalysisProceedDisableState;
    };

    /**
     * Check if any line item selected for proceed
     * @returns true or false
     */
    const getSelectedLineItemsState = () => {
        let vSelectedLineItemsState = false;
        if (
            (selectedTableDetailsTab === 'idTabContentLineAnalysis') &&
            (checkRowIds.length <= 0)
        ) {
            vSelectedLineItemsState = true;
        }
        return vSelectedLineItemsState;
    };
    /**
     * Disable the prceed button for ore than 15 line items selection
     */
    const getMaxLineItemsDisablestate = () => {
        let vMaxLineItemsDisablestate= false;
        if (
            (selectedTableDetailsTab === 'idTabContentLineAnalysis') &&
            (checkRowIds.length > MAX_SELECTION)
        ) {
            vMaxLineItemsDisablestate = true;
        }
        return vMaxLineItemsDisablestate;
    };

    const lineAnalysisWarningClose =()=>{
        setLineAnalysisWarning(false)
    }
    /**
     * Get tooltip text for Proceed button disable
     * @returns tooltip text
     */
    const getProceedDisabledTooltipText = () => {
        if (getLineAnalysisProceedDisableState()) {
            return 'Either the Line Analysis or the Table Analysis is still in progress';
        } else if (isTableAnalysisGenerationLastOperation()) {
            return (<>Table Analysis < br />reflects the latest <br />Line Analysis</>);
        } else if (getTableAnalysisProceedDisableState()) {
            return 'Either none of the line items have analysis, or the Line Analysis is still in progress.';
        } else if (getSelectedLineItemsState()) {
            return 'Select at least one line item to continue';
        } else if (getMaxLineItemsDisablestate()) {
            return (
                <>Select up to<br />{MAX_SELECTION} rows at a time </>  
            )
        } else {
            return 'Analysis in progress';
        }
    };

    const handleExpandIconClick = (event, vLineItem) => {
        getLineAnalysisDetails(vLineItem.rowId);
        setCurrentVLineItem(vLineItem);
        setShowLAPopover(true);
    }

    const onLAPopoverClose = () => {
        setShowLAPopover(false);
        setShowNotificationOnModal(false);
        setActiveTab(vTabs.lineAnalysis[0].elementId);
    }

    /**
     * Function to manage accordion click
     * @param {*} event 
     * @param {*} vLineItem 
     */
    const setUpAnalysisDetails = (event, vLineItem) => {
        if ((event.target.className === 'motif-checkbox-input motif-visually-hidden')) {
            const { rowId , RowHeader} = vLineItem;
            if (!vLineItem.selected) {
                const vCheckRowIds = [...checkRowIds, rowId];
                setCheckRowIds(vCheckRowIds);
                const vCheckRowHeaders = [...checkedRowHeader, RowHeader];
                setCheckedRowHeader(vCheckRowHeaders);
                if (isProceedBtnDisabled) {
                    setIsProceedBtnDisabled(false);
                }
                if (isClearBtnDisabled) {
                    setIsClearBtnDisabled(false);
                }
                if ((vCheckRowIds.length === getEnabledCheckboxesCount()) && !isSelectAll) {
                    setIsSelectAll(true);
                }
            } else {
                const vCheckRowIds = checkRowIds.filter((item) => item !== rowId);
                setCheckRowIds(vCheckRowIds);
                const vCheckRowHeaders = checkedRowHeader.filter((item) => item !== RowHeader);
                setCheckedRowHeader(vCheckRowHeaders);
                if ((vCheckRowIds.length !== getEnabledCheckboxesCount()) && isSelectAll) {
                    setIsSelectAll(false);
                }
            }
            const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
            for (const element of vSelectedTableData.tableContent) {
                if (element.rowId === rowId) {
                    element.selected = !vLineItem.selected;
                }
            }

            for (const element of displayTableData.tableContent) {
                if (element.rowId === rowId) {
                    element.selected = !vLineItem.selected;
                }
            }
            Object.preventExtensions(vSelectedTableData);
            setSelectedTableData(vSelectedTableData);
            setDisplayTableData(displayTableData);
            window.scrollTo(0, 0);
        }
        else {
            if (!vLineItem.open) {
                const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
                for (const element of vSelectedTableData.tableContent) {
                    if (element.rowId === vLineItem.rowId) {
                        element.open = true;
                    } else {
                        element.open = false;
                    }
                }
                Object.preventExtensions(vSelectedTableData);
                setSelectedTableData(vSelectedTableData);
                setDisplayTableData(vSelectedTableData);
                getLineAnalysisDetails(vLineItem.rowId);
            } else if (vLineItem.open) {
                const vSelectedTableData = JSON.parse(JSON.stringify(selectedTableData));
                for (const element of vSelectedTableData.tableContent) {
                    if (element.rowId === vLineItem.rowId) {
                        element.open = false;
                    }
                }
                Object.preventExtensions(vSelectedTableData);
                setSelectedTableData(vSelectedTableData);
                setDisplayTableData(vSelectedTableData);
            }
        }
    };

    /**
     * Function to open add KPIs/Metrics popup
     */
    const setAdditionalContext = () => {
        setDisplayAdditionalContextModal(true);
    }
    /**
         * This method handles export modal close.
         */
    const onExportQnAModalClose = () => {
        setShowExportQnAModal(false);
    }

    /**
     * This method receives export status.
     * @param {number} statusCode 
     */
    const receiveExportStatus = (statusCode) => {
        if (statusCode === 200) {
            setNotificationMessage("Q&A exported successfully");
            setShowNotification(true);
            setNotificationType("success");
        }
        else {
            setNotificationMessage("Export failure");
            setShowNotification(true);
            setNotificationType("error");
        }
    }

    /**
     * This method receives update Q&A status.
     * @param {number} status 
     */
    const receiveUploadQnAStatus = (status) => {
        if (status === 'success') {
            setIsLineCommentaryInitiated(false);
            getExportImportDetails(selectedTable);
            setNotificationMessage("Q&A updated successfully and Line Analysis is in progress");
            setShowNotification(true);
            setNotificationType("success");
            setShowUploadQnALoader(false);
        }
        else {
            setIsLineCommentaryInitiated(false);
            getExportImportDetails(selectedTable);
            setNotificationMessage("Q&A upload failed due to a technical error. Please retry uploading the Q&A.");
            setShowNotification(true);
            setNotificationType("error");
            setShowUploadQnALoader(false);
        }
    }

    const onThresholdModalClose = () => {
        setShowTableThresholdModal(false);
    }

    const receiveThresholdUpdateStatus = (statusCode) => {
        // eslint-disable-next-line
        if (statusCode == 200) {
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage('Threshold settings updated successfully.');
        }
        else {
            setShowNotification(true);
            setNotificationType("error");
            setNotificationMessage('Failed to update table threshold settings.');
        }
    }

    const generateLineCommentaryInExcel = async () => {        
        const LineCommentaryRowData = checkRowIds?.filter(rowId => 
            rowStatusData.find(row => row.rowId === rowId)?.status === "Completed"
        );
        const lineCommData = await tableService.lineCommToExcel(projectId,tableId,LineCommentaryRowData, userId);
        if(window.Excel) {
            window.Excel.run(async (context) => {

                //  Extract the workbook name (filename)
                const workbook = context.workbook;
                workbook.load("name");
                await context.sync();
                const workbookName = workbook.name;

                if (selectedTable?.metadata?.filename !== workbookName) {
                    return;
                }

                // Get the selected range
                const selectedRange = context.workbook.getSelectedRange();
                selectedRange.load(["worksheet/name"]);
                // Sync the context to load the selected range properties
                await context.sync();
                // Extract the values and address from the selected range
                const sheetName = selectedRange.worksheet.name;

                if (selectedTable?.metadata?.sheetName !== sheetName) {
                    return;
                }

                if (selectedTable?.metadata?.uploadRange) {

                    const vLineCommentaryHeaderText = 'Line Commentary';

                    const targetSheet = context.workbook.worksheets.getItem(sheetName);
                    const vRange = selectedTable?.metadata?.uploadRange;

                    const vCellToUpdate = await getColumnLineCommentary(targetSheet, vRange, context);

                    const pasteRange = `${vCellToUpdate.column}${vCellToUpdate.row}`;
                    let cellToUpdate = targetSheet.getRange(pasteRange);
                    cellToUpdate.load("format/font");
                    cellToUpdate.load("values");
                    await context.sync();

                    // If cell already contains Line commentary header, don't update
                    let currentValues = cellToUpdate.values;
                    let headerValue;
                    let isUpdateNeeded = false;
                    if (currentValues.length > 0 && currentValues[0].length > 0) {
                        if (vLineCommentaryHeaderText !== currentValues[0][0]) {
                            headerValue = vLineCommentaryHeaderText;
                            isUpdateNeeded = true;
                        } else {
                            headerValue = currentValues[0][0];
                        }
                    } else {
                        headerValue = vLineCommentaryHeaderText;
                        isUpdateNeeded = true;
                    }
                    if (isUpdateNeeded) {
                        utils.writeValueToExcelCell('Header', cellToUpdate, headerValue);
                        await context.sync();
                    }

                    let vLineCommentaryCell = JSON.parse(JSON.stringify(vCellToUpdate));
                    for (const element of lineCommData?.results) {
                        const vLineItemIndex = selectedTable.tableContent.findIndex(ele => (ele.rowId === element.resultId));
                        if (vLineItemIndex !== -1) {
                            vLineCommentaryCell.row = vCellToUpdate.row + (vLineItemIndex + 1);
                            const vLineAnswer = element?.answer?.answer;
                            const pasteRange = `${vLineCommentaryCell.column}${vLineCommentaryCell.row}`;
                            let cellToUpdate = targetSheet.getRange(pasteRange);
                            cellToUpdate.load("format/font");
                            cellToUpdate.load("values");
                            await context.sync();

                            if (vLineAnswer) {
                                // If cell already contains Line commentary value as same then will not update
                                let currentValues = cellToUpdate.values;
                                let cellValue;
                                let isUpdateNeeded = false;
                                if (currentValues.length > 0 && currentValues[0].length > 0) {
                                    if (vLineAnswer !== currentValues[0][0]) {
                                        cellValue = vLineAnswer;
                                        isUpdateNeeded = true;
                                    } else {
                                        cellValue = currentValues[0][0];
                                    }
                                } else {
                                    cellValue = vLineAnswer;
                                    isUpdateNeeded = true;
                                }
                                if (isUpdateNeeded) {
                                    utils.writeValueToExcelCell('Body', cellToUpdate, cellValue);
                                    await context.sync();
                                }
                            }
                        }
                    }
                }

            }).catch((error) => {
                console.error("Error: " + error);
            });
        }
        clearAllLineItemSelection();
        setCheckRowIds([]);
        setCheckedRowHeader([]);
        setIsProceedBtnDisabled(true);
        setIsClearBtnDisabled(true);
    }

    const generateTableCommentaryInExcel = () => {
        if (window.Excel) {
            window.Excel.run(async (context) => {

                // Extract the workbook name (filename)
                const workbook = context.workbook;
                workbook.load("name");
                await context.sync();
                const workbookName = workbook.name;

                if (selectedTable?.metadata?.filename !== workbookName) {
                    return;
                }

                // Get the selected range
                const selectedRange = context.workbook.getSelectedRange();
                selectedRange.load(["worksheet/name"]);
                // Sync the context to load the selected range properties
                await context.sync();
                // Extract the values and address from the selected range
                const sheetName = selectedRange.worksheet.name;

                if (selectedTable?.metadata?.sheetName !== sheetName) {
                    return;
                }

                if (selectedTable?.metadata?.uploadRange) {

                    const vLineCommentaryHeaderText = 'Line Commentary';
                    const vTableCommentaryHeaderText = 'Table Commentary';

                    const targetSheet = context.workbook.worksheets.getItem(sheetName);
                    const vRange = selectedTable?.metadata?.uploadRange;

                    const vCellToUpdate = await getColumnLineCommentary(targetSheet, vRange, context);

                    let pasteRange = `${vCellToUpdate.column}${vCellToUpdate.row}`;
                    let cellToUpdate = targetSheet.getRange(pasteRange);
                    cellToUpdate.load("format/font");
                    cellToUpdate.load("values");
                    await context.sync();

                    // If cell already contains Line commentary header, don't update
                    let currentValues = cellToUpdate.values;
                    let headerValue;
                    let isUpdateNeeded = false;
                    if (currentValues.length > 0 && currentValues[0].length > 0) {
                        if (vLineCommentaryHeaderText !== currentValues[0][0]) {
                            headerValue = vLineCommentaryHeaderText;
                            isUpdateNeeded = true;
                        } else {
                            headerValue = currentValues[0][0];
                        }
                    } else {
                        headerValue = vLineCommentaryHeaderText;
                        isUpdateNeeded = true;
                    }
                    if (isUpdateNeeded) {
                        utils.writeValueToExcelCell('Header', cellToUpdate, headerValue);
                        await context.sync();
                    }

                    let vLineCommentaryCell = vCellToUpdate;

                    let vTableCommentaryCell = vLineCommentaryCell;
                    const vLineItemIndex = selectedTable.tableContent.length;
                    vTableCommentaryCell.row += vLineItemIndex + 2;
                    pasteRange = `${vTableCommentaryCell.column}${vTableCommentaryCell.row}`;
                    cellToUpdate = targetSheet.getRange(pasteRange);
                    cellToUpdate.load("format/font");
                    cellToUpdate.load("values");
                    await context.sync();

                    // If cell already contains Table commentary header, don't update
                    currentValues = cellToUpdate.values;
                    headerValue = vTableCommentaryHeaderText;
                    isUpdateNeeded = false;
                    if (currentValues.length > 0 && currentValues[0].length > 0) {
                        if (vTableCommentaryHeaderText !== currentValues[0][0]) {
                            isUpdateNeeded = true;
                        } else {
                            headerValue = currentValues[0][0];
                        }
                    } else {
                        isUpdateNeeded = true;
                    }
                    if (isUpdateNeeded) {
                        utils.writeValueToExcelCell('Header', cellToUpdate, headerValue);
                        await context.sync();
                    }

                    const vTableAnalysis = await analysisService.getTableAnalysisData(tableId)
                    if (vTableAnalysis && (vTableAnalysis.length > 0)) {
                        const isAnswerExist = vTableAnalysis.some(item => item.hasOwnProperty('answer'));
                        if (isAnswerExist === true) {
                            dispatch(setTableAnalysis({
                                tableAnalysis: vTableAnalysis[0]
                            }));
                            vTableCommentaryCell.row += 1;
                            const pasteRange = `${vTableCommentaryCell.column}${vTableCommentaryCell.row}`;
                            let cellToUpdate = targetSheet.getRange(pasteRange);
                            cellToUpdate.load("format/font");
                            cellToUpdate.load("values");
                            await context.sync();
                            // If cell already contains Line commentary value as same then will not update
                            let currentValues = cellToUpdate.values;
                            let cellValue;
                            let isUpdateNeeded = false;
                            if (currentValues.length > 0 && currentValues[0].length > 0) {
                                if (vTableAnalysis[0].answer !== currentValues[0][0]) {
                                    cellValue = vTableAnalysis[0].answer;
                                    isUpdateNeeded = true;
                                } else {
                                    cellValue = currentValues[0][0];
                                }
                            } else {
                                cellValue = vTableAnalysis[0].answer;
                                isUpdateNeeded = true;
                            }
                            if (isUpdateNeeded) {
                                utils.writeValueToExcelCell('Body', cellToUpdate, cellValue);
                                await context.sync();
                            }
                        }
                    }
                }

            }).catch((error) => {
                console.error("Error: " + error);
            });
        }
        
    }

    /**
     * Function to get cell to write line commentary
     * @param {*} vSheet 
     * @param {*} vRange 
     * @param {*} vContext 
     * @returns 
     */
    const getColumnLineCommentary = async (vSheet, vRange, vContext) => {
        const firstCell = utils.splitExcelCell(vRange.split(":")[0]);
        const lastCell = utils.splitExcelCell(vRange.split(":")[1]);
        const vRow = firstCell.row;
        let vColumn = utils.letterToNum(lastCell.column);
        const noOfCols = 100;
        for (let i = vColumn + 1; i < noOfCols; i++) {
            const curColumn = utils.numToLetters(i);
            const curCellValue = await getCellValue(vRow, curColumn, vSheet, vContext);
            const nextColumn = utils.numToLetters(i + 1);
            const nextCellValue = await getCellValue(vRow, nextColumn, vSheet, vContext);
            if ((curCellValue === '') && ((nextCellValue === '') || (nextCellValue === 'Line Commentary'))) {
                return { column: nextColumn, row: vRow };
            }
        }
        return null;
    };

    /**
     * Function to get cell value
     * @param {*} vRow 
     * @param {*} vCol 
     * @param {*} vSheet 
     * @param {*} vContext 
     * @returns 
     */
    const getCellValue = async (vRow, vCol, vSheet, vContext) => {
        const cellRange = `${vCol}${vRow}`;
        const curCell = vSheet.getRange(cellRange);
        curCell.load("format/font");
        curCell.load("values");
        await vContext.sync();
        return curCell.values[0][0];
    };

    /**
     * Function to get source reference
     * @param {*} vDocRef
     */
    const getSourceReference = (vDocRef) => {
        if (isExcelFileFormat(vDocRef.source)) {
            return getExcelSourceReference(vDocRef);
        } else {
            return getNonExcelSourceReference(vDocRef);
        }
    };

    /**
     * Function to check if excel file format or not
     * @param {*} vSource
     * @returns
     */
    const isExcelFileFormat = (vSource) => {
        let vIsExcelFileFormat = false;
        if (vSource) {
            const isFileFormatFound = vExcelFileFormats.find(
                (element) => vSource.toLowerCase().indexOf(element) !== -1
            );
            if (isFileFormatFound) {
                vIsExcelFileFormat = true;
            }
        }
        return vIsExcelFileFormat;
    };

    /**
     * Function to get Non excel source references
     * @param {*} vDocRef
     * @returns
     */
    const getNonExcelSourceReference = (vDocRef) => {
        let vSourceRef = "";
        if (vDocRef?.page) {
            vSourceRef = vDocRef.source + ", Page Number - " + vDocRef.page;
        } else {
            vSourceRef = vDocRef.source;
        }
        return vSourceRef;
    };

    /**
     * Function to get excel source reference
     * @param {*} vDocRef
     */
    const getExcelSourceReference = (vDocRef) => {
        let vSourceRef = "";
        if (vDocRef?.sheet_name) {
            vSourceRef = vDocRef.source + ", Sheet Name - " + vDocRef.sheet_name;
        } else {
            vSourceRef = vDocRef.source;
        }
        return vSourceRef;
    };

    /**
     * Function to check if ellipsis applied to table name
     * @returns 
     */
    const isEllipsis = () => {
        let vIsEllipsis = false;
        const idTableName = document.getElementById('idTableName');
        if (idTableName && (idTableName.scrollWidth > idTableName.clientWidth)) {
            vIsEllipsis = true;
        }
        return vIsEllipsis;
    };

    /**
     * Function to identify view source documents dropdown is toggled in commentary section
     * @param {*} vLineItemId 
     */
    const toggleDocsDropdown = (vLineItemId) => {
        vwSrcDocsDropdownId ? setVwSrcDocsDropdownId(null) : setVwSrcDocsDropdownId(vLineItemId);
    }

    /**
     * Function to identify view source documents dropdown is toggled from Q&A section
     * @param {*} vElement 
     * @param {*} vId 
     * @param {*} vIndex 
     * @param {*} vType 
     */
    const toggleVwSrcDocsQA = (vElement, vId, vIndex, vType) => {
        if (vIndex !== null) {
            if (vType === 'toggle') {
                if (idVwSrcDocsQA && (idVwSrcDocsQA !== vId)) {
                    setIsOpenVwSrcDocsQA(true);
                } else {
                    setIsOpenVwSrcDocsQA(!isOpenVwSrcDocsQA);
                }
                setIdVwSrcDocsQA(vId);
                setIndexVwSrcDocsQA(vIndex);
                setToggleTypeVwSrcDocsQA(vType);
                vElement['selectedId'] = vId;
                vElement['selectedIndex'] = vIndex;
            }
        }
    };

    /**
     * Hook to set dropdown display when view source documents dropdown is toggled in Q&A section
     */
    useEffect(() => {
        if (toggleTypeVwSrcDocsQA) {
            if (toggleTypeVwSrcDocsQA === 'toggle') {
                const vDropdown = document.getElementById("more-dropdown-vsd-" + idVwSrcDocsQA + "-" + indexVwSrcDocsQA);
                if (vDropdown && (vDropdown.style.display === 'none')) {    //  isOpenVwSrcDocsQA
                    vDropdown.style.display = 'block';
                    setDisplayUpArrowIcon(true);
                } else if (vDropdown) {
                    vDropdown.style.display = 'none';
                    setDisplayUpArrowIcon(false);
                }
            }
        }
    }, [toggleTypeVwSrcDocsQA, idVwSrcDocsQA, indexVwSrcDocsQA, isOpenVwSrcDocsQA]);

    /**
     * Function to clear view source documents dropdown variables
     * @param {*} vElement 
     * @param {*} vEvent 
     */
    const clearVwSrcDocsQA = (vElement) => {
        if (vElement?.selectedId && (vElement.extractionId === vElement.selectedId)) {
            const vDropdown = document.getElementById("more-dropdown-vsd-" + vElement.extractionId + "-" + vElement.selectedIndex);
            if (vDropdown && (vDropdown.style.display === 'block')) {
                vDropdown.style.display = 'none';
                setIdVwSrcDocsQA(null);
                setIndexVwSrcDocsQA(null);
                setToggleTypeVwSrcDocsQA(null);
                setIsOpenVwSrcDocsQA(false);
                setDisplayUpArrowIcon(false);
            }
        }
    };

    /**
     * Function to clear all line item selections
     */
    const clearAllLineItemSelection = () => {
        const vSelectedTableData = JSON.parse(JSON.stringify(displayTableData));
        for (const element of vSelectedTableData.tableContent) {
            element.selected = false;
        }
        Object.preventExtensions(vSelectedTableData);
        //setSelectedTableData(vSelectedTableData);
        setDisplayTableData(vSelectedTableData);
        if (isSelectAll) {
            setIsSelectAll(false);
        }
    };

    const filterLineItems = (e) => {
        setSearchValue(e.target.value);
        let filteredLineItems = selectedTableData?.tableContent?.filter((lineItem) => {
            return lineItem.RowHeader.toLowerCase().includes(e.target.value.toLowerCase());
        })
        setDisplayTableData({
            ...displayTableData,
            tableContent: filteredLineItems
        });

        setReapplySelections(!reapplySelections);
    }

    //  Check if commentary available
    const isCommentaryAvailable = (vType) => {
        let vIsCommentaryAvailable = false;
        if (vType === "Line") {
            const vLineAnalysisResults = lineAnalysisData?.results;
            if (vLineAnalysisResults && (vLineAnalysisResults.length > 0)) {
                const vLineAnalysisAnswer = vLineAnalysisResults[2]?.answer;
                if (vLineAnalysisAnswer && (vLineAnalysisAnswer !== "")) {
                    vIsCommentaryAvailable = true;
                }
            }
        } else {
            if (tableAnalysisData && (tableAnalysisData.length > 0)) {
                const vTableAnalysisAnswer = tableAnalysisData[0]?.answer;
                if (vTableAnalysisAnswer && (vTableAnalysisAnswer !== "")) {
                    vIsCommentaryAvailable = true;
                }
            }
        }
        return vIsCommentaryAvailable;
    };

    /**
     * Function to clear commentary
     * @param {*} vData 
     */
    const clearCommentaryInExcel = (vData) => {
        if (window.Excel) {
            window.Excel.run(async (context) => {

                // Extract the workbook name (filename)
                const workbook = context.workbook;
                workbook.load("name");
                await context.sync();
                const workbookName = workbook.name;

                if (selectedTable?.metadata?.filename !== workbookName) {
                    return;
                }

                // Get the selected range
                const selectedRange = context.workbook.getSelectedRange();
                selectedRange.load(["worksheet/name"]);
                // Sync the context to load the selected range properties
                await context.sync();
                // Extract the values and address from the selected range
                const sheetName = selectedRange.worksheet.name;

                if (selectedTable?.metadata?.sheetName !== sheetName) {
                    return;
                }

                if (selectedTable?.metadata?.uploadRange) {

                    const targetSheet = context.workbook.worksheets.getItem(sheetName);
                    const vRange = selectedTable?.metadata?.uploadRange;

                    const vCellToUpdate = await getColumnLineCommentary(targetSheet, vRange, context);

                    const pasteRange = `${vCellToUpdate.column}${vCellToUpdate.row}`;
                    let cellToUpdate = targetSheet.getRange(pasteRange);
                    cellToUpdate.load("format/font");
                    cellToUpdate.load("values");
                    await context.sync();

                    let vLineCommentaryCell = vCellToUpdate;
                    for (const element of selectedTable.tableContent) {
                        vLineCommentaryCell.row += 1;
                        const vRow = vData?.rowIds?.find(ele => (ele.rowId === element.rowId));
                        if (vRow?.status !== 'Completed') {
                            const pasteRange = `${vLineCommentaryCell.column}${vLineCommentaryCell.row}`;
                            let cellToUpdate = targetSheet.getRange(pasteRange);
                            cellToUpdate.load("format/font");
                            cellToUpdate.load("values");
                            await context.sync();

                            // If cell already contains Line commentary value as same then will not update
                            let currentValues = cellToUpdate.values;
                            let cellValue = "";
                            let isUpdateNeeded = false;
                            if (currentValues.length > 0 && currentValues[0].length > 0) {
                                if (currentValues[0][0] !== "") {
                                    isUpdateNeeded = true;
                                }
                            }
                            if (isUpdateNeeded) {
                                utils.writeValueToExcelCell('Body', cellToUpdate, cellValue);
                                await context.sync();
                            }
                        }
                    }

                    let vTableCommentaryCell = vLineCommentaryCell;
                    if (vData?.tableAnalysisStatus !== "Completed") {
                        vTableCommentaryCell.row += 2;
                        let pasteRange = `${vTableCommentaryCell.column}${vTableCommentaryCell.row}`;
                        let cellToUpdate = targetSheet.getRange(pasteRange);
                        cellToUpdate.load("format/font");
                        cellToUpdate.load("values");
                        await context.sync();

                        // If cell already contains Line commentary header, don't update
                        let currentValues = cellToUpdate.values;
                        let headerValue = "";
                        let isUpdateNeeded = false;
                        if (currentValues.length > 0 && currentValues[0].length > 0) {
                            if (currentValues[0][0] !== "") {
                                isUpdateNeeded = true;
                            }
                        }
                        if (isUpdateNeeded) {
                            utils.writeValueToExcelCell('Header', cellToUpdate, headerValue);
                            await context.sync();
                        }

                        vTableCommentaryCell.row += 1;
                        pasteRange = `${vTableCommentaryCell.column}${vTableCommentaryCell.row}`;
                        cellToUpdate = targetSheet.getRange(pasteRange);
                        cellToUpdate.load("format/font");
                        cellToUpdate.load("values");
                        await context.sync();

                        // If cell already contains Line commentary value as same then will not update
                        currentValues = cellToUpdate.values;
                        let cellValue = "";
                        isUpdateNeeded = false;
                        if (currentValues.length > 0 && currentValues[0].length > 0) {
                            if (currentValues[0][0] !== "") {
                                isUpdateNeeded = true;
                            }
                        }
                        if (isUpdateNeeded) {
                            utils.writeValueToExcelCell('Body', cellToUpdate, cellValue);
                            await context.sync();
                        }
                    }
                }

            }).catch((error) => {
                console.error("Error: " + error);
            });
        }
    }

      /**
     * Function to choose either line analysis or table analysis
     */
    const handleAnalysisCLick = (id) => {
        if(id===1)
        {
            setIsLineAnalysis(true);
            setIsTableAnalysis(false);
        }
        else
        {   
            setIsLineAnalysis(false);
            setIsTableAnalysis(true);
        }
        handleTabClick("Table Details", vTabs.tableDetails.find(item => item.id === id));
    }

    return (
        <div className="tableDetailsContainer">
            <div className="tableDetailsBreadcrumb">
                <MotifIconButton
                    aria-label="Click here to go back to Project Details"
                    onClick={() => navToProjectDetails()}
                    className="btnBack"
                    data-testid="testIdBtnBack"
                >
                    <img src={iconArrowBack} alt="Previous" />
                </MotifIconButton>
                <div className="fluid">
                    <div className="tableName" id="idTableName">
                        <MotifTooltip
                            hide={!isEllipsis()}
                            trigger={selectedTableData?.tableName}
                            allowClick={false}
                            hideCloseButton={true}
                        >
                            {selectedTableData?.tableName}
                        </MotifTooltip>
                    </div>
                </div>
                <span className="ellipsisText">{(isEllipsis() ? '...' : '')}</span>
                <div className="tableActions">
                        <MotifIconButton
                            aria-label="Click here for Line Analysis"
                            className={`btnTableActions btnWithBorder ${isLineAnalysis ? 'clicked' : ''}`} 
                            onClick={() => handleAnalysisCLick(1)}
                        >
                            <MotifTooltip                            
                                trigger={<img src={showLineAnalysisIcon  ? iconLineAnalysisRed : iconLineAnalysis}
                                alt="Line Analysis" />}
                                placement="left"

                                contentClassName="tooltipWithoutClose"
                                allowClick={false}
                                hideCloseButton={true}
                            >
                                {!showLineAnalysisIcon  ? 
                                (<div>Line Analysis </div>) :
                                (<><div className="tcTooltipTitle">Line commentary updates available</div>
                                    <div className="tcTooltipBody">
                                        There has been a change to the additional<br
                                        />  information used to generate the line commentary.<br />
                                        Generate the line commentary again to see an <br /> updated
                                        result.
                                    </div></>)}
                            </MotifTooltip>
                        </MotifIconButton>
                        <MotifIconButton
                        aria-label="Click here for Table Analysis"
                        className={`btnTableActions btnWithBorder ${isTableAnalysis ? 'clicked' : ''}`} 
                        onClick={() => handleAnalysisCLick(2)}
                        data-testid="testIdBtnTableAnalysis"
                    >
                            <MotifTooltip
                                trigger={                                    
                                    <img src={showTableAnalysisIcon  ? iconTableAnalysisRed : iconTableAnalysis}  
                                    alt="Table Analysis" />
                                }
                                placement="left"
                                contentClassName="tooltipWithoutClose"
                                allowClick={false}
                                hideCloseButton={true}
                            >                                
                                {!showTableAnalysisIcon  ? 
                                (<div>Table Analysis </div>) :
                                (<><div className="tcTooltipTitle">Table commentary updates available</div>
                                    <div className="tcTooltipBody">
                                        There has been a change to the line analysis used to<br
                                        />  generate the table commentary. Generate the table<br />
                                        commentary again to see an updated result.
                                    </div></>)}
                            </MotifTooltip>
                        </MotifIconButton>
                </div>
            </div>
            <div className="tableContent">                
                <div className="tabContentLineAnalysis" id="idTabContentLineAnalysis">
                    {
                        !isLoading && !isLineCommentaryInitiated &&
                        <>
                            <div className="search-bar-container">
                                <div className="search search-bar">
                                    <MotifFormField>
                                        <MotifSearch
                                            aria-label="Search"
                                            placeholder="Search"
                                            value={searchValue}
                                            onChange={filterLineItems}
                                            searchButtonProps={{ hidden: true }}
                                        />
                                        <img src={searchIcon} alt='Search' className='iconSearch' />
                                    </MotifFormField>
                                </div>
                                <div className="icon-container">
                                    <MotifIconButton
                                    aria-label="Click here for Q&A"
                                    className="btnTableActions btnDocument"
                                    disabled={!isSomeLineAnalysisGenerated }
                                    onClick={() => showQA()}
                                    >
                                        <MotifTooltip
                                            trigger={<img src={iconQA} alt="Q&A" />}
                                            placement="bottom"

                                            contentClassName="tooltipWithoutClose"
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            Q&A
                                        </MotifTooltip>
                                    </MotifIconButton>
                                    {displayQAModal &&
                                        <DisplayQAModal
                                            openModal={displayQAModal}
                                            setOpenModal={setDisplayQAModal}
                                            showExportQnAClick={showExportQnAClick}
                                            showUploadQnAClick={showUploadQnAClick}
                                            fileName={(exportImportDetails && exportImportDetails.FileName) ? exportImportDetails.FileName : activeProject.projectName + "_" + selectedTable.tableName + "_QnA_V_" + exportImportDetails?.Version + ".xlsm"}
                                            lineAnalysisStatus={lineAnalysisStatus}
                                            showUploadQnALoader={showUploadQnALoader}
                                            uploadconfirmation={exportImportDetails?.Export_Import_Flag}
                                        />
                                    }  
                                     <MotifIconButton
                                        aria-label="Click here for source documents"
                                        className="btnTableActions btnDocument"
                                        disabled={!((usedDocumentList && usedDocumentList.length > 0))}
                                        data-testid="testIdBtnDocument"
                                        onClick={() => showDocuments()}
                                    >
                                        <MotifTooltip
                                            trigger={<img src={showDocumentIcon ? document_red : iconDocument} alt="Source Documents" />}
                                            placement="bottom"

                                            contentClassName="tooltipWithoutClose"
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            {showDocumentIcon ? (
                                                <>There has been a change to <br />
                                                    the Documents  for this table, Select<br />
                                                    a row(s) and click 'Proceed' to <br />
                                                    regenerate Line Analysis
                                                </>) : (
                                                "Documents"
                                            )}

                                        </MotifTooltip>
                                    </MotifIconButton>
                                    {displayDocumentsModal &&
                                        <Documents
                                            openModal={displayDocumentsModal}
                                            setOpenModal={setDisplayDocumentsModal}
                                            filterDocuments={usedDocumentList}
                                            filterdelDocumentList={deletedDocumentList}
                                        />
                                    }
                                     <MotifIconButton
                                        aria-label="Click here for table relationships"
                                        className="btnTableActions btnNetwork"
                                        data-testid="testIdBtnNetwork"
                                        onClick={() => setTableRelationship()}
                                        disabled={
                                            (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                                            !isRelationshipEnabled ||
                                            (lineAnalysisStatus === 'In-Progress') ||
                                            (isTableAnalysisGenerated === "In-Progress")
                                        }
                                    >
                                        <MotifTooltip
                                            trigger={<img src={showRelationshipIcon ? relationship_red : iconRelationship}
                                                alt="Table Relationships" />}

                                            placement="bottom"
                                            contentClassName="tooltipWithoutClose"
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            {showRelationshipIcon ? (
                                                <>There has been a change to the<br />
                                                    relationships for this table,<br />
                                                    Select a row(s) and click 'Proceed'<br />
                                                    to regenerate Line Analysis
                                                </>) : (
                                                "Relationship"
                                            )}

                                        </MotifTooltip>
                                    </MotifIconButton>
                                    <MotifIconButton
                                        aria-label="KPIs/Metrics"
                                        className="btnTableActions btnAddContext"
                                        data-testid="testIdBtnAddContext"
                                        onClick={() => setAdditionalContext()}
                                        disabled={
                                            (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                                            (lineAnalysisStatus === 'In-Progress') ||
                                            (isTableAnalysisGenerated === "In-Progress")
                                        }
                                    >
                                        <MotifTooltip
                                            trigger={<img src={showKpiIndIcon ? additinal_context_red : iconAdditionalContext} alt="KPIs/Metrics" />}
                                            placement="bottom"
                                            contentClassName="tooltipWithoutClose"
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            {showKpiIndIcon ? (
                                                <>There has been a change to <br />
                                                    the KPIs/Metrics for this table,<br />
                                                    Select a row(s) and click 'Proceed'<br />
                                                    to regenerate Line Analysis
                                                </>) : (
                                                "KPIs/Metrics"
                                            )}

                                        </MotifTooltip>
                                    </MotifIconButton>
                                    {displayAdditionalContextModal &&
                                        <AdditionalContext
                                            openModal={displayAdditionalContextModal}
                                            setOpenModal={setDisplayAdditionalContextModal}
                                            closeModal={closeAdditionalContextModal}
                                            selectedTable={selectedTable}
                                        />
                                    }
                                    <MotifIconButton
                                        aria-label="Click here for Threshold"
                                        className='btnTableActions btnThreshold'
                                        onClick={() => openThresholdPopover()}
                                        data-testid="testIdBtnThreshold"
                                        disabled={
                                            (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                                            (lineAnalysisStatus === 'In-Progress') ||
                                            (isTableAnalysisGenerated === "In-Progress")
                                        }
                                    >
                                        <MotifTooltip
                                            trigger={
                                                <img src={iconThreshold} alt="Threshold" />
                                            }
                                            placement="bottom"
                                            contentClassName="tooltipWithoutClose"
                                            allowClick={false}
                                            hideCloseButton={true}
                                        >
                                            Thresholds
                                        </MotifTooltip>
                                    </MotifIconButton>
                                    {displayTableRelationshipModal &&
                                        <TableRelationship
                                            openModal={displayTableRelationshipModal}
                                            setOpenModal={setDisplayTableRelationshipModal}
                                            projectId={projectId}
                                            tableSelected={selectedTable}
                                            closeModal={closeTableRelationshipModal}
                                            tables={tables}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="contentLineAnalysis">
                            {
                                displayTableData?.tableContent?.map((vLineItem) => (
                                    <div className="line-div" key={vLineItem.rowId}>
                                        <MotifCheckbox
                                            disabled={
                                                ((rowStatusData?.find((row) => row.rowId === vLineItem.rowId) || {}).status === "In-Progress") ||
                                                ((rowStatusData?.find((row) => row.rowId === vLineItem.rowId) || {}).status === "Disabled")
                                            }
                                            checked={vLineItem.selected}
                                            id={"lineItemCheckbox" + vLineItem.rowId}
                                            name="line item checkbox"
                                            onChange={($event) => setUpAnalysisDetails($event, vLineItem)}
                                            value={vLineItem.selected}
                                            className="lineItemCheckbox"
                                        ></MotifCheckbox>
                                        <div className="accordionTitle">{vLineItem.RowHeader}</div>
                                        <div className="status-div">
                                            {((rowStatusData.find((row) => row.rowId === vLineItem.rowId) || {}).status === "In-Progress") &&
                                                (
                                                    <MotifTooltip
                                                        trigger={<img src={iconProgressLoader} alt="Loading" className="progress-loader"></img>}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                    >
                                                        Analysis in progress
                                                    </MotifTooltip>
                                                )
                                            }
                                            {rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.status === "Completed" &&
                                                (
                                                    <>
                                                        {(
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowdocumentInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowrelationshipInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowkpiInd === 0) && (
                                                                <img className="icon-check-red" src={line_circle_red} alt="Completed"></img>
                                                            )}

                                                        {!(
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowdocumentInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowrelationshipInd === 0 ||
                                                            rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.rowkpiInd === 0) && (
                                                                <img className="icon-check-green" src={iconCheckCircle} alt="Completed"></img>
                                                            )}
                                                    </>
                                                )}

                                            {((rowStatusData.find((row) => row.rowId === vLineItem.rowId) || {}).status === "Failure") &&
                                                (
                                                    <MotifTooltip hideCloseButton="true" trigger={
                                                        <img src={warning_error} alt="Error" className="errorMsg"></img>
                                                    } >
                                                        <center> Error in Line Analysis <br />
                                                            Please try again</center>
                                                    </MotifTooltip>
                                                )
                                            }
                                            {
                                                ((rowStatusData?.find((row) => row.rowId === vLineItem.rowId) || {}).status === "Disabled") && (
                                                <MotifTooltip
                                                    trigger={<img src={iconInfo} alt="Help" className="iconInfo"></img>}
                                                    contentClassName="tooltipWithoutClose"
                                                    allowClick={false}
                                                    hideCloseButton={true}
                                                >
                                                    Row excluded due to applied thresholds
                                                </MotifTooltip>
                                            )}
                                            {
                                                <MotifIconButton
                                                    disabled={rowStatusData.find((row) => row.rowId === vLineItem.rowId)?.status
                                                        !== "Completed"}
                                                    onClick={(e) => handleExpandIconClick(e, vLineItem)}
                                                >
                                                    <img src={expandButton} alt="expand"></img>
                                                </MotifIconButton>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                !displayTableData?.tableContent?.length && (
                                    <div className="parent-nolineitems-div">
                                        <div className="no_nolineitems_div">
                                            <img src={featured_icon} alt='featured_icon' className="no_nolineitems_image" />
                                            <p className="no_nolineitems_text">No line items match the search criteria</p>
                                        </div>
                                    </div>
                                )
                            }
                            </div>
                        </>
                    }
                    {
                        !isLoading && isLineCommentaryInitiated &&
                        (
                            <div className="tableAnalysisLoaderDiv">
                                <div>
                                    <img src={iconProgressLoaderCircle} alt='Progress' className="tableAnalysisProgress" />
                                </div>
                                <div className="loaderText">
                                    Line analysis being generated...
                                </div>
                            </div>
                        )
                    }
                    {
                        isLoading &&
                        (
                            <div className="tableAnalysisLoaderDiv">
                                <div>
                                    <img src={iconProgressLoaderCircle} alt='Progress' className="tableAnalysisProgress" />
                                </div>
                                <div className="loaderText">
                                    Loading...
                                </div>
                            </div>
                        )
                    }
                </div>
                <div id="viewLineAnalysisPopover">
                    <MotifModal
                        show={showLAPopover}
                        onClose={onLAPopoverClose}
                        size='xl'
                        className="lineAnalysisModal genericModal modalMotifFooterModal"
                    >
                        <MotifModalHeader
                            closeButtonProps={{
                                'aria-label': 'Close',
                                title: 'Close'
                            }}
                            className="lineAnalysisPopoverHeader"
                        >
                            <div>{currentVLineItem.RowHeader}</div>
                        </MotifModalHeader>
                        <MotifModalBody className="modal-body-lineanalysis">
                            <MotifInlineMessage
                                variant="warning"
                                icons={
                                    {
                                        message: <img src={iconWarningInfoCircle} className="iconWarningInfoCircle" alt="Warning" />
                                    }
                                }
                                className="msgAIContainer"
                            >
                                <span className="msgAI">AI generated output may contain inaccuracies or errors. Verify output before use.</span>
                            </MotifInlineMessage>
                            <MotifTabNavigation defaultActiveKey={0} className='tabControl'>
                                {
                                    vTabs.lineAnalysis.map((vTab) =>
                                        <MotifTabControl key={vTab.id} onClick={() =>
                                            handleTabClick('Line Analysis', vTab, currentVLineItem.rowId)}
                                            className='btnTabControl' data-testid="testIdBtnTabControl">
                                            {vTab.title}
                                        </MotifTabControl>
                                    )
                                }
                            </MotifTabNavigation>
                            <div
                                className="tabContentDescription"
                                id={"idTabContentDescription~" + currentVLineItem.rowId}
                            >
                                <div className="contentLayer">
                                    {!displayLineAnalysis('lineDescription', currentVLineItem) &&
                                        <div className='noContentLayer'>
                                            <div className="noContentTitle">No description found</div>
                                            <div className='noContentBody'>Click 'Proceed' and run line commentary to view description</div>
                                        </div>
                                    }
                                    {displayLineAnalysis('lineDescription', currentVLineItem) &&
                                        <>
                                            <div className='genAILayer'>
                                                <MotifIconButton
                                                    aria-label="AI Generated"
                                                    className='genAIActions btnGenAI'
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={iconGenAI} alt='AI Generated' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                        placement="bottom"
                                                    >
                                                        AI generated
                                                    </MotifTooltip>

                                                </MotifIconButton>
                                            </div>
                                            <div className='content'
                                                dangerouslySetInnerHTML={{
                                                    __html: utils.markdownToHtml(extractLineAnalysisData('lineDescription', 'answer')),
                                                }}
                                            ></div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='tabContentQA' id={'idTabContentQA~' + currentVLineItem.rowId}>
                                {!displayLineAnalysis('lineQuestions', currentVLineItem) &&
                                    <div className="contentLayer">
                                        <div className='noContentLayer'>
                                            <div className="noContentTitle">No Q&A found</div>
                                            <div className='noContentBody'>Click 'Proceed' in Line Analysis and enable Q&A in Analysis Settings</div>
                                        </div>
                                    </div>
                                }
                                {displayLineAnalysis('lineQuestions', currentVLineItem) && extractLineAnalysisData('lineQuestions', 'extractions').map((element, index) =>
                                    <div className="contentLayer contentQAOutline" key={element.extractionId}>
                                        <div className='content'>
                                            <div className='questionAnswerLayer'>
                                                <div className='questionTitle'>
                                                    <div className='questionText'>Q&A {index + 1}</div>
                                                    <div className='spacer'></div>
                                                </div>
                                                <div className='questionSubTitle'>
                                                    <div className='questionText'>Question</div>
                                                    <div className='spacer'></div>
                                                    <MotifIconButton
                                                        aria-label="AI Generated"
                                                        className='contentActions btnGenAI'
                                                    >
                                                        <MotifTooltip
                                                            trigger={<img src={
                                                                (element?.extraction_flags?.type === 'AI') ?
                                                                    iconGenAI :
                                                                    iconUser
                                                            } alt='AI Generated' />}
                                                            contentClassName="tooltipWithoutClose"
                                                            allowClick={false}
                                                            hideCloseButton={true}
                                                            placement="bottom"
                                                        >
                                                           {element?.extraction_flags?.type === 'AI' ?"AI generated":"User generated"}

                                                        </MotifTooltip>

                                                    </MotifIconButton>
                                                </div>
                                                <div className='questionContent'>
                                                    {element.extraction}
                                                </div>
                                                <div className='answerTitle'>
                                                    <div className='answerText'>Answer</div>
                                                    <div className='spacer'></div>
                                                    <MotifIconButton
                                                        aria-label="AI Generated"
                                                        className='contentActions btnGenAI'
                                                    >
                                                        <MotifTooltip
                                                            trigger={<img src={
                                                                (element?.answer[0]?.extraction_flags?.type === 'AI') ?
                                                                    iconGenAI :
                                                                    iconUser
                                                            } alt='AI Generated' />}
                                                            contentClassName="tooltipWithoutClose"
                                                            allowClick={false}
                                                            hideCloseButton={true}
                                                            placement="bottom"
                                                        >
                                                             {element?.answer[0]?.extraction_flags?.type === 'AI' ?"AI generated":"User generated"}
                                                        </MotifTooltip>

                                                    </MotifIconButton>
                                                </div>
                                                <div className='answerContent'>
                                                    {element.answer[0]?.extraction}
                                                </div>
                                                <div className='questionAnswerActions'>
                                                    <MotifDropdown
                                                        className="dropdownViewSrcDocs"
                                                        aria-labelledby={"more-dropdown-trigger-vsd-" + element.extractionId}
                                                        handleClickOutside={() => clearVwSrcDocsQA(element)}
                                                        id={"more-dropdown-vsd-" + element.extractionId + "-" + index}
                                                        placement="top-placement"
                                                        trigger={
                                                            <MotifIconButton
                                                                className='btnArrowDown'
                                                                aria-label="More"
                                                                id={"more-dropdown-trigger-vsd-" + element.extractionId}
                                                                onClick={() => toggleVwSrcDocsQA(element, element.extractionId, index, 'toggle')}
                                                                disabled={!(
                                                                    element.answer[0]?.doc_reference &&
                                                                    (element.answer[0]?.doc_reference.length > 0)
                                                                )}>
                                                                <span className='viewSrcDocsText'>View Source Documents</span>
                                                                <img className="iconViewSrcDocs" src={((element.extractionId === idVwSrcDocsQA) && (index === indexVwSrcDocsQA) && displayUpArrowIcon) ? iconArrowUp : iconArrowDown} alt='View Source Documents' />
                                                            </MotifIconButton>
                                                        }
                                                    >
                                                        {element.answer[0]?.doc_reference && element.answer[0]?.doc_reference.map((vRef, vIndex) =>
                                                            <div key={vIndex} className={`sourceDocList ${vRef.IsDeleted ? "deleted" : "active"}`} >
                                                                <MotifDropdownItem onClick={() => { }}>{getSourceReference(vRef)}</MotifDropdownItem>
                                                                {vRef.IsDeleted && <MotifTooltip key={vIndex + 1}
                                                                    trigger={<img src={brokenLink} alt="Deleted" />}
                                                                    placement="left"
                                                                    contentClassName=""
                                                                    allowHover={true}
                                                                    allowClick={false}
                                                                    hideCloseButton={true}
                                                                >
                                                                    Document deleted
                                                                </MotifTooltip>}
                                                            </div>
                                                        )}
                                                    </MotifDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="commentary-toast">
                            <div
                                className="tabContentCommentary"
                                id={"idTabContentCommentary~" + currentVLineItem.rowId}
                            >
                                <div className="contentLayer">
                                    {!displayLineAnalysis('lineCommentary', currentVLineItem) &&
                                        <div className='noContentLayer'>
                                            <div className="noContentTitle">No commentary found</div>
                                            <div className='noContentBody'>Click 'Proceed' and run line commentary to view commentary</div>
                                        </div>
                                    }
                                    {displayLineAnalysis('lineCommentary', currentVLineItem) &&
                                        <>
                                            <div className='genAILayer'>
                                                <MotifIconButton
                                                    aria-label="Like"
                                                    className='genAIActions btnLike'
                                                    onClick={() => onSubmitFeedabck('Like', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback'))}
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={(getFeedback('Like', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback')) === 1) ? iconThumbsUpFilled : iconThumbsUp} alt='Like' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                        placement="bottom"
                                                    >
                                                        Like
                                                    </MotifTooltip>
                                                </MotifIconButton>
                                                <MotifIconButton
                                                    aria-label="Dislike"
                                                    className='genAIActions btnDislike'
                                                    onClick={() => onSubmitFeedabck('Dislike', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback'))}
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={(getFeedback('Dislike', currentVLineItem, extractLineAnalysisData('lineCommentary', 'feedback')) === -1) ? iconThumbsDownFilled : iconThumbsDown} alt='Dislike' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                        placement="bottom"
                                                    >
                                                        Dislike
                                                    </MotifTooltip>
                                                </MotifIconButton>
                                                <MotifIconButton
                                                    aria-label="AI Generated"
                                                    className='genAIActions btnGenAI'
                                                >
                                                    <MotifTooltip
                                                        trigger={<img src={iconGenAI} alt='AI Generated' />}
                                                        contentClassName="tooltipWithoutClose"
                                                        allowClick={false}
                                                        hideCloseButton={true}
                                                        placement="bottom"
                                                    >
                                                        AI generated
                                                    </MotifTooltip>
                                                </MotifIconButton>
                                                <MotifDropdown
                                                    className="dropdownMore"
                                                    aria-labelledby={"more-dropdown-trigger-" + currentVLineItem.rowId}
                                                    handleClickOutside={() => toggleDropdownMenuMore(currentVLineItem.rowId, 'Close')}
                                                    id={"more-dropdown-" + currentVLineItem.rowId}
                                                    placement="bottom-right"
                                                    trigger={
                                                        <MotifIconButton
                                                            className='genAIActions btnMore'
                                                            aria-label="More"
                                                            id={"more-dropdown-trigger-" + currentVLineItem.rowId}
                                                            onClick={() => toggleDropdownMenuMore(currentVLineItem.rowId, 'Open')}>
                                                            <img src={iconMore} alt='More' />
                                                        </MotifIconButton>
                                                    }
                                                >
                                                    <MotifDropdownItem onClick={() => onCopy(currentVLineItem.rowId)}>
                                                        <img src={iconCopy} alt="Copy" className='iconCopy' /> Copy
                                                    </MotifDropdownItem>
                                                </MotifDropdown>
                                            </div>
                                            <div className={'content lineCommentary-' + currentVLineItem.rowId}
                                                dangerouslySetInnerHTML={{
                                                    __html: utils.markdownToHtml(extractLineAnalysisData('lineCommentary', 'answer')),
                                                }}
                                            ></div>
                                            <div className='questionAnswerActions'>
                                                <MotifDropdown
                                                    className="dropdownViewSrcDocs"
                                                    aria-labelledby={"more-dropdown-trigger-vsd-" + currentVLineItem.rowId}
                                                    handleClickOutside={() => setVwSrcDocsDropdownId(null)}
                                                    id={"more-dropdown-vsd-" + currentVLineItem.rowId}
                                                    placement="top-placement"
                                                    open={vwSrcDocsDropdownId === currentVLineItem.rowId}
                                                    trigger={
                                                        <MotifIconButton
                                                            className='btnArrowDown'
                                                            aria-label="More"
                                                            id={"more-dropdown-trigger-vsd-" + currentVLineItem.rowId}
                                                            onClick={() => toggleDocsDropdown(currentVLineItem.rowId)}
                                                            disabled={!(
                                                                extractLineAnalysisData("lineCommentary", "doc_reference") &&
                                                                (extractLineAnalysisData("lineCommentary", "doc_reference").length > 0)
                                                            )}>
                                                            <span className='viewSrcDocsText'>View Source Documents</span>
                                                            <img className="iconViewSrcDocs" src={(vwSrcDocsDropdownId === currentVLineItem.rowId) ? iconArrowUp : iconArrowDown} alt='View Source Documents' />
                                                        </MotifIconButton>
                                                    }
                                                >
                                                    {extractLineAnalysisData('lineCommentary', 'doc_reference').map((vDocRef, vDocRefIndex) =>
                                                        <div key={vDocRefIndex} className={`sourceDocList ${vDocRef.IsDeleted ? "deleted" : "active"}`} >
                                                            <MotifDropdownItem onClick={() => { }}>{getSourceReference(vDocRef)}</MotifDropdownItem>
                                                            {vDocRef.IsDeleted && <MotifTooltip key={vDocRefIndex + 1}
                                                                trigger={<img src={brokenLink} alt="Deleted" />}
                                                                placement="left"
                                                                contentClassName=""
                                                                allowHover={true}
                                                                allowClick={false}
                                                                hideCloseButton={true}
                                                            >
                                                                Document deleted
                                                            </MotifTooltip>}
                                                        </div>

                                                    )}
                                                </MotifDropdown>

                                            </div>
                                        </>
                                    }

                                </div>           
                            </div>
                            {showNotificationOnModal && activeTab === commentaryTabId &&
                                <div className=" toast-container">
                                    <MotifToast
                                        actionName=""
                                        onClose={() => setShowNotificationOnModal(false)}
                                        variant={notificationTypeOnModal}
                                        className='notifyToast lineAnalysisToast'
                                        icons={{
                                            toast:
                                                <img src={
                                                    (notificationTypeOnModal === 'success') ? iconCheckCircle :
                                                        (notificationTypeOnModal === 'error') ? iconError :
                                                            (notificationTypeOnModal === 'warning') ? iconWarning :
                                                                iconWhiteCheckCircle
                                                } alt={
                                                    (notificationTypeOnModal === 'success') ? "Success" :
                                                        (notificationTypeOnModal === 'error') ? "Failure" :
                                                            (notificationTypeOnModal === 'warning') ? "Warning" :
                                                                "Info"
                                                }
                                                />
                                        }}
                                    >
                                        {notificationMessageOnModal}
                                    </MotifToast>
                                </div>
                                }
                            </div>        
                        </MotifModalBody>
                        <MotifModalFooter className="analysis-footer">
                      
                            {
                                (activeTab === commentaryTabId) && 
                                (
                                    <div className="transferToPPT">
                                        <MotifButton variant="secondary"
                                            onClick={() => handleTransferToPPTClick(true)} 
                                            disabled={
                                                showNotificationOnModal || 
                                                isButtonDisabled || 
                                                showToast || 
                                                !displayLineAnalysis('lineCommentary', currentVLineItem) ||
                                                !isCommentaryAvailable("Line")
                                            }>
                                            <div className="tarnsferbuttoon">
                                                <img src={IconDataTransfer} alt="ppt transferimage" />
                                                <span className="tarnsfertext">Transfer to PPT</span>
                                            </div>
                                        </MotifButton>
                                    </div>
                                )
                            }
                        </MotifModalFooter>

                    </MotifModal>
                </div>
                <div className="tabContentTableAnalysis" id="idTabContentTableAnalysis">
                    {!isTableAnalysisGenerated && !(isTableCommentaryInitiated && !isTableCommentaryGenerated) && (<div className="tableAnalysisDiv">
                        {/* <div className="tableAnalysisCommentry">Commentary</div> */}
                        <div className="tableAnalysisNoFoundTextDiv">
                            <div className="tableAnalysisNoFoundText">No analysis found</div>
                            <div className="tableAnalysisNoFoundClickText">
                                Click on Proceed to generate table commentary
                            </div>
                        </div>
                    </div>)}
                    {((isTableAnalysisGenerated === "In-Progress") || (isTableCommentaryInitiated && !isTableCommentaryGenerated)) && (<div className="tableAnalysisLoaderDiv">
                        <div>
                            <img src={iconProgressLoaderCircle} alt='Progress' className="tableAnalysisProgress" />
                        </div>
                        <div className="loaderText">
                            Table analysis being generated...
                        </div>
                    </div>)}
                    {(isTableAnalysisGenerated === "Completed") && !(isTableCommentaryInitiated && !isTableCommentaryGenerated) && 
                        <Commentary 
                        selectedTableData={selectedTableData} 
                        isTableCommentaryInitiated={isTableCommentaryInitiated} 
                        isTableCommentaryGenerated={isTableCommentaryGenerated}
                        isTableAnalysisGenerated={isTableAnalysisGenerated}
                        isAllLineAnalysisNotGenerated={isAllLineAnalysisNotGenerated}
                        lineAnalysisStatus={lineAnalysisStatus}
                        showTransferToastTableAnalysis={showTransferToastTableAnalysis}
                        isCommentaryAvailable={isCommentaryAvailable}
                        handleTransferToPPTClick={handleTransferToPPTClick}
                        />}
                    {(isTableAnalysisGenerated === "Failure") && !(isTableCommentaryInitiated && !isTableCommentaryGenerated) && <div className="container">
                        <div className="content">
                            <div className="top-bar">
                                {/* <div><span className="page-title">Commentary</span></div> */}
                            </div>
                            <div className="body-content">
                                <div>
                                    <img className="img-failure" src={iconError} alt="failure" />
                                </div>
                                <div className="page-title">
                                    Analysis failed due to a technical error
                                </div>
                                <div>
                                    Please click on 'Proceed' and try again
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {
                    showTransferToastTableAnalysis &&
                    <div className="toast-div-commentry">
                        <MotifToast
                            onClose={function noRefCheck() { setShowTransferToastTableAnalysis(false); }}
                            variant="success"
                            icons={{ toast: <img src={iconCheckCircle} alt="Success" /> }}
                        >
                            Transfer successful
                        </MotifToast>
                    </div>
                }
                {(showNotification) &&
                    <MotifToast
                        actionName=""
                        onClose={() => setShowNotification(false)}
                        variant={notificationType}
                        className={notificationType === 'info' ? 'clearAnalysisToast notifyToast' : 'notifyToast'}
                        icons={{
                            toast:
                                <img src={
                                    (notificationType === 'success') ? iconCheckCircle :
                                        (notificationType === 'error') ? iconError :
                                            (notificationType === 'warning') ? iconWarning :
                                                iconWhiteCheckCircle
                                } alt={
                                    (notificationType === 'success') ? "Success" :
                                        (notificationType === 'error') ? "Failure" :
                                            (notificationType === 'warning') ? "Warning" :
                                                "Info"
                                }
                                />
                        }}
                    >
                        {notificationMessage}
                    </MotifToast>
                }
                 {lineAnalysisWarning &&
                    <MotifToast
                        variant="warning"
                        actionName=""
                        position=""
                        onClose={() => {
                            lineAnalysisWarningClose();
                        }}
                        className='notifyToast lineanalysisprogress'
                        icons={{
                            toast: <img src={iconWarning} alt="Warning" />
                        }}
                    >
                        <div>Analysis is in progress </div>
                        <div className="toast-content">
                            Line Analysis on this table is currently in progress.
                            Initiating analysis on further line items at this time
                            may result in Line Analysis taking longer to complete and/or a system error.
                        </div>
                    </MotifToast>

                }

                {
                    showTableThresholdModal && <TableThreshold onClose={onThresholdModalClose} receiveThresholdUpdateStatus={receiveThresholdUpdateStatus} ></TableThreshold>
                }
            </div>
            {
                (
                    (selectedTableDetailsTab === vTabIds.tableDetails.lineAnalysis) ||
                    (selectedLineAnalysisTab === vTabIds.lineAnalysis.description) ||
                    (selectedLineAnalysisTab === vTabIds.lineAnalysis.qa) ||
                    (selectedLineAnalysisTab === vTabIds.lineAnalysis.commentary)
                ) &&
                <div className='genericFooter'>
                    <div className='footerInner alignSpaceBetween'>
                        <div className="left-buttons">
                            {getSelectedLineItemsState() ? (<MotifInlineMessage
                                    variant="info"
                                    icons={
                                        {
                                            message: <img src={iconInfo} className="iconInfoAddtoExcel" alt="info" />
                                        }
                                    }
                                    className="msgForAddtoExcel"
                                >
                                    <span className="inlineMsgAddtoExcel">Select line items to apply actions</span>
                                </MotifInlineMessage>) : null}
                            {(selectedTableDetailsTab === vTabIds.tableDetails.lineAnalysis) &&
                                <>
                                    {displayUploadQAModal &&
                                        <UploadQA
                                            openModal={displayUploadQAModal}
                                            setOpenModal={setDisplayUploadQAModal}
                                            setOpenModalForUploadProgress={setFromUploadProcess}
                                            userId={user.username}
                                            projectId={activeProject.project_UUID}
                                            tableId={selectedTable.id}
                                            exportedFileName={(exportImportDetails && exportImportDetails.FileName) ? exportImportDetails.FileName : activeProject.projectName + "_" + selectedTable.tableName + "_QnA_V_" + exportImportDetails.Version + ".xlsm"}
                                            setShowNotification={setShowNotification}
                                            setNotificationMessage={setNotificationMessage}
                                            setNotificationType={setNotificationType}
                                            exportImportDetails={exportImportDetails}
                                            setUploadQALoader={setShowUploadQnALoader}
                                        />
                                    }
                                </>
                            }
                        </div>
                        <div className="right-buttons">
                            {
                                (selectedTableDetailsTab === 'idTabContentTableAnalysis')
                                ? 
                                (
                                    <div className="button-group">
                                        {/* <MotifButton className="abc" variant="secondary"
                                            disabled={
                                                (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                                                !isTableAnalysisGenerated ||
                                                (isTableAnalysisGenerated === "In-Progress") || 
                                                (isAllLineAnalysisNotGenerated && !isTableCommentaryGenerated) ||
                                                (
                                                    (lineAnalysisStatus === 'In-Progress')  && (!isTableCommentaryGenerated)
                                                ) ||
                                                showTransferToastTableAnalysis ||
                                                !isCommentaryAvailable("Table")
                                            }
                                            onClick={() => handleTransferToPPTClick(false)} >
                                            <div className="tarnsferbuttoon">
                                                <img src={IconDataTransfer} alt="ppt transferimage" />
                                                <span className="tarnsfertext">Transfer to PPT</span>
                                            </div>
                                        </MotifButton> */}
                                        <MotifButton className="btnAddToExcel" variant="secondary"
                                            disabled={
                                                !(
                                                    (isTableAnalysisGenerated === "Completed") && 
                                                    !(
                                                        isTableCommentaryInitiated && 
                                                        !isTableCommentaryGenerated)
                                                    ) ||
                                                !isCommentaryAvailable("Table")
                                            }
                                            onClick={() => handleTableAddtoExcelClick()}
                                        >
                                            <span className="addToExcelEllipses">Add to Excel</span>
                                        </MotifButton>
                                        <MotifButton className="" variant="secondary" 
                                            disabled={!isTableCommentaryGenerated}                                          
                                            onClick={() => {}}
                                        >
                                            <span className="addToExcelEllipses">Clear</span>
                                        </MotifButton>
                                        <MotifTooltip trigger={
                                            <MotifButton
                                                onClick={() => proceed()}
                                                className="btnProceed"
                                                disabled={
                                                    isProceedBtnDisabled ||
                                                    getTableAnalysisProceedDisableState() ||
                                                    getLineAnalysisProceedDisableState() ||
                                                    getSelectedLineItemsState()
                                                }
                                            >
                                                Proceed
                                            </MotifButton>}
                                            allowHover={
                                                isProceedBtnDisabled ||
                                                getTableAnalysisProceedDisableState() ||
                                                getLineAnalysisProceedDisableState() ||
                                                getSelectedLineItemsState()
                                            }
                                            contentClassName="tooltipWithoutClose"
                                                placement={
                                                    (
                                                        (isTableCommentaryInitiated && !isTableCommentaryGenerated) ||
                                                        (isTableAnalysisGenerated === "In-Progress")
                                                    )
                                                    ?
                                                    "top"
                                                    :
                                                    undefined
                                                }
                                                allowClick={false}
                                                hideCloseButton={true}
                                                hide={
                                                    !!(
                                                        (
                                                            (isTableCommentaryInitiated && !isTableCommentaryGenerated) || 
                                                            (isTableAnalysisGenerated === "In-Progress")
                                                        )
                                                    )
                                                }
                                            >
                                            {
                                                getProceedDisabledTooltipText()
                                            }
                                        </MotifTooltip>
                                    </div>
                                )
                                :
                                (
                                <div className="btnGroupWithClear" >
                                <MotifButton className="btnClear" variant="secondary"
                                    disabled={isClearBtnDisabled || getSelectedLineItemsState()}
                                    onClick={() => handleClearBtnClick()}
                                >
                                    Clear
                                </MotifButton>
                                <MotifButton className="btnAddToExcel" variant="secondary"
                                    disabled={getSelectedLineItemsState()}
                                    onClick={() => handleAddtoExcelClick()}
                                >
                                    <span className="addToExcelEllipses">Add to Excel</span>
                                </MotifButton>
                                <MotifTooltip trigger={
                                    <MotifButton
                                        onClick={() => proceed()}
                                        className="btnProceed"
                                        disabled={
                                            isProceedBtnDisabled ||
                                            getTableAnalysisProceedDisableState() ||
                                            getLineAnalysisProceedDisableState() ||
                                            getSelectedLineItemsState() ||
                                            getMaxLineItemsDisablestate()
                                        }
                                    >
                                        Proceed
                                    </MotifButton>}
                                    allowHover={
                                        isProceedBtnDisabled ||
                                        getTableAnalysisProceedDisableState() ||
                                        getLineAnalysisProceedDisableState() ||
                                        getSelectedLineItemsState() ||
                                        getMaxLineItemsDisablestate()
                                    }
                                    contentClassName="tooltipWithoutClose"
                                    allowClick={false}
                                    hideCloseButton={true}
                                    placement={getMaxLineItemsDisablestate()? "top": undefined}
                                >
                                    {
                                        getProceedDisabledTooltipText()
                                    }
                                </MotifTooltip>
                                </div>)
                            }
                        </div>
                    </div>
                    {showClearModal && (
                        <ClearLineAnalysis
                            onClose={handleClearModalClose}
                            handleClearAnalysis={handleClearAnalysis}
                            checkedRowHeader={checkedRowHeader}
                        />
                    )}
                    {(showLineCommentaryModal || fromUploadProcess) && (
                        <AnalysisSettings
                            initSource={fromUploadProcess ? "upload" : "proceed"}
                            openModal={showLineCommentaryModal || fromUploadProcess}
                            setOpenModal={setShowLineCommentaryModal}
                            setOpenModalForUploadProgress={setFromUploadProcess}
                            setDisplayUploadQAModal={setDisplayUploadQAModal}
                            fromUploadProcess={fromUploadProcess}
                            closeModal={handleCloseModal}
                            onConfirm={handleConfirmClick}
                            lineAnalysisStatus={lineAnalysisStatus}
                            documentList={usedDocumentList}
                            isCallForTableAnalysis={isCallForTableAnalysis}
                            selectedTable={selectedTable}
                            exportedFileName={(exportImportDetails && exportImportDetails.FileName) ? exportImportDetails.FileName : activeProject.projectName + "_" + selectedTable.tableName + "_QnA_V_" + exportImportDetails.Version + ".xlsm"}
                            receiveUploadQnAStatus={receiveUploadQnAStatus}
                            setUploadQALoader={setShowUploadQnALoader}
                        />
                    )}
                    {showExportQnAModal && (
                        <ExportQnA
                            show={showExportQnAModal}
                            onClose={onExportQnAModalClose}
                            fileName={(exportImportDetails && exportImportDetails.FileName) ? exportImportDetails.FileName : activeProject.projectName + "_" + selectedTable.tableName + "_QnA_V_" + exportImportDetails?.Version + ".xlsm"}
                            downloadedBy={exportImportDetails.UserID}
                            downloadedOn={exportImportDetails.ExportDate}
                            previouslyExported={true}
                            showConfirmation={!exportImportDetails?.Export_Import_Flag}
                            receiveExportStatus={receiveExportStatus}
                            tableId={tableId}
                            projectId={projectId}
                        ></ExportQnA>
                    )}
                </div>
            }
        </div>
    );
};

TableDetails.propTypes = {
    tableLAStat: PropTypes.string,
    onCloseSelectedTable: PropTypes.func,
    tables: PropTypes.array
};

export default TableDetails;